import React, { Component } from 'react';
import Select from 'react-select';
import moment from 'moment';
import { Link } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Drawer from '@material-ui/core/Drawer';
import swal from 'sweetalert';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import _ from 'lodash';
import Divider from '@material-ui/core/Divider';
import SongSearch from '../../components/SongSearch/SongSearch';
import './PresentationsPage.css';

import PresentationsService from '../../services/presentations.js';
import SongsService from '../../services/songs';

class PresentationsPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			presentations: [],
			songs: [],
			isEdit: false,
			selectedPresentation: {},
			selectedSongs: [],
			selectedAddEditTitle: "",
			selectedAddEditDate: "",
			selectedAddEditMessage: "",

			selectedVideoTitle: "",
			selectedVideoImgURL: "",
			selectedVideoID: "",
			selectedVideoAltID: "",
			selectedVideoSpeaker: "Rev. Placido Lentini",
			selectedVideoStatus: "scheduled",
			selectedVideoStartTime: "",

			songsList: [],
			lastUpdated: "",
			action: ""
		}
	}

	componentDidMount() {
		const self = this;

		PresentationsService.RetrieveAll().then(function(response) {
			console.log(response);
			self.setState({
				presentations: response
			});
		})

		SongsService.RetrieveAll().then(function(response) {
			self.setState({
				songs: response
			});
		});
	}

	edit(id) {
		const selectedPresentation = _.find(this.state.presentations, { 'presentationID': id });

		this.setState({
			isEdit: true,
			action: "edit",
			selectedPresentation: selectedPresentation,
			selectedSongs: selectedPresentation.songs,
			selectedAddEditTitle: selectedPresentation.title,
			selectedAddEditDate: selectedPresentation.dateFor,
			selectedAddEditMessage: selectedPresentation.message,
		});

		const self = this;
		PresentationsService.RetrieveVideoDetails(id).then(function(response) {
			if (response.length > 0) {
				self.setState({
					selectedVideoTitle: response[0].videoTitle,
					selectedVideoImgURL: response[0].videoImageURL,
					selectedVideoID: response[0].videoID,
					selectedVideoAltID: response[0].videoAltID,
					selectedVideoSpeaker: response[0].videoSpeaker,
					selectedVideoStatus: response[0].videoStatus,
					selectedVideoStartTime: response[0].videoStartTime
				});
			}
		})
	}

	save(pAction) {
		const self = this;
		const { presentations, action } = this.state;
		var tempPresentations = presentations;
		const selectPresentationID = this.state.selectedPresentation.presentationID ? this.state.selectedPresentation.presentationID : "";
		const now = new Date();
		var tempPayload = {};
		const payload = {
			presentationID: selectPresentationID,
			title: this.state.selectedAddEditTitle,
			videoTitle: this.state.selectedVideoTitle,
			videoImgURL: this.state.selectedVideoImgURL,
			videoID: this.state.selectedVideoID,
			videoAltID: this.state.selectedVideoAltID,
			videoSpeaker: this.state.selectedVideoSpeaker,
			videoStatus: this.state.selectedVideoStatus,
			videoStartTime: this.state.selectedVideoStartTime,
			dateFor: moment(this.state.selectedAddEditDate).format('MMMM DD YYYY'),
			message: this.state.selectedAddEditMessage,
			dateCreated: now,
			lastUpdated: now,
			notes: [],
			slides: [],
			
			owner: "1",
			active: pAction == "delete" ? "0" : "1",
			songs: this.state.selectedSongs
		}

		var songs = this.state.songs;
		payload.songs.forEach(function(item, index, arr) {
			let slides = _.filter(songs, function (s) {
				return s.songID === item.songID;
			});
			let songTitle = { "details": item.name, "order": 0, "type": "title" };
			let songSlides = slides[0].lyrics.slides;
			let songSlidesConcat = [songTitle].concat(songSlides);

			payload.slides = payload.slides.concat(songSlidesConcat);
		})	

		tempPayload = payload;
		tempPayload.slides = JSON.stringify(tempPayload.slides);
		tempPayload.songs = JSON.stringify(tempPayload.songs);


		PresentationsService.SavePresentation(tempPayload).then(function(response) {
			payload.slides = JSON.parse(payload.slides);
			payload.songs = JSON.parse(payload.songs);

			let title = "Saved";
			let text = "The presentation has been saved.";
			if (pAction === "delete") {
				title = "Deleted";
				text = "The presentation has been deleted.";
			}

			if (action !== "create") {
				const key = _.findKey(presentations, function(o) { return o.presentationID === selectPresentationID; });
				tempPresentations[key] = payload;
				self.setState({ presentations: tempPresentations });
			}

			if (action === "create") {
				title = "Created";
				text = "The presentation has been created.";
				payload.presentationID = response.toString();
				console.log(payload)
				tempPresentations = [payload].concat(tempPresentations);
				self.setState({ presentations: tempPresentations });
				self.closeEdit();
			}

			swal({
				title: title,
				text: text,
				icon: "success",
			})

			if (pAction === "delete" || pAction === "create") {
				self.closeEdit();
			}
		});
	}

	create() {
		var d = new Date();
		var month = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
		var day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();

		var selectedVideoStartTime = d.getFullYear() + "-" + month + "-" + day + ' 10:45:00';

		var resultDate = new Date(d.getTime());
        resultDate.setDate(d.getDate() + (7 + 0 - d.getDay() - 1) % 7 +1);

		var sundayDate = (resultDate.getMonth() + 1) + "/" + resultDate.getDate() + "/" + resultDate.getFullYear();

		this.setState({
			isEdit: true,
			action: "create",
			selectedPresentation: {},
			selectedSongs: [],
			selectedAddEditTitle: "Sunday Service",
			selectedAddEditDate: sundayDate,
			selectedAddEditMessage: "",
			selectedVideoTitle: "Sunday Service",
			selectedVideoImgURL: "",
			selectedVideoID: "",
			selectedVideoAltID: "",
			selectedVideoSpeaker: "Rev. Placido Lentini",
			selectedVideoStatus: "SCHEDULED",
			selectedVideoStartTime: selectedVideoStartTime
		});
	}

	delete() {
		const self = this;
		swal({
			title: "Are you sure?",
			text: "The presentation will be deleted.",
			icon: "error",
			buttons: true,
			dangerMode: true,
		})
		.then((willDelete) => {
			if (willDelete) {
				this.save("delete");
				return true;
			}
			else {
				return false;
			}
		});
	}

	closeEdit() {
		this.setState({
			isEdit: false,
			action: "",
			selectedPresentation: {},
			selectedSongs: [],
			selectedAddEditTitle: "",
			selectedAddEditDate: "",
			selectedAddEditMessage: "",
			selectedVideoTitle: "",
			selectedVideoImgURL: "",
			selectedVideoID: "",
			selectedVideoAltID: "",
			selectedVideoSpeaker: "",
			selectedVideoStatus: "",
			selectedVideoStartTime: ""
		});
	}

	handleFormChange = name => event => {
		this.setState({ [name]: event.target.value })
	};

	handleSongSearch = (selectedOption) => {
		const selectedSong = { songID: selectedOption.value, name: selectedOption.label };
		this.setState({ selectedSongs: [...this.state.selectedSongs, selectedSong] });
	}

	removedSelectedSong = (removedSong) => {
		const updatedSelectedSong = _.filter(this.state.selectedSongs, function (o) {
			return o.songID != removedSong;
		});
		this.setState({ selectedSongs: updatedSelectedSong });
	}

	clearSelectedSongs = () => {
		this.setState({ selectedSongs: [] });
	}

	start = (presentation) => {

	}

	render() {
		const { presentations, songs, isEdit, action, selectedPresentation, selectedAddEditTitle, selectedAddEditDate, selectedAddEditMessage, selectedVideoTitle, selectedVideoImgURL, selectedVideoID, selectedVideoAltID, selectedVideoSpeaker, selectedVideoStatus, selectedVideoStartTime, selectedSongs } = this.state;

		var selectedAddEditDateTemp = "";
		if (selectedAddEditDate) {
			selectedAddEditDateTemp = moment(selectedAddEditDate).format('YYYY-MM-DD');
		}

		const suggestions = songs.map(suggestion => ({
			value: suggestion.songID,
			label: suggestion.name,
		}));

		return (
			<div id="presentations-page" className="margin-25">
				<Fab className="add-fab" onClick={() => this.create()}>
					<AddIcon/>
				</Fab>

				<Grid container spacing={16}>
					<Grid item xs={0} sm={1}></Grid>
					<Grid item xs={12} sm={10}>
						<Grid container spacing={16}>
							{presentations
								.filter(function (presentation) {
									return presentation.active === "1"
								})
								.map((item, index) => (
									<Grid item xs={12} sm={6} md={4} key={item.presentationID}>
										<Card className="card">
											{/* <CardHeader
												title={item.title}
												subheader={item.dateFor}
												className="preview"
											/>
											<CardMedia
												component="img"
												alt="Contemplative Reptile"
												height="200"
												image="https://cdn.wallpapersafari.com/84/51/28gj4X.jpg"
												title="Contemplative Reptile"
											/> */}
											<CardContent>
												<Typography className="title" color="textSecondary" gutterBottom>
													{item.title}
												</Typography>
												<Typography variant="h6" component="h2">{item.dateFor}</Typography>
												<Typography component="p">
													{item.message}
												</Typography>
											</CardContent>
											<CardActions>
												<Link to={`/presentation/${item.presentationID}`}><Button size="small">Start</Button></Link>
												<Button size="small" onClick={() => this.edit(item.presentationID)}>View / Edit</Button>
												<Link to={`/live/${item.presentationID}`} target="_blank"><Button size="small">Share</Button></Link>
												<Link to={`/remote/${item.presentationID}`} target="_blank"><Button size="small">Remote</Button></Link>
												{/* <Button size="small" onClick={() => this.save("delete")}>Delete</Button> */}
											</CardActions>
										</Card>
									</Grid>
								))}
						</Grid>
					</Grid>
					<Grid item xs={0} sm={1}></Grid>
				</Grid>

				<Drawer
					anchor="right"
					open={this.state.isEdit}
					onClose={() => this.closeEdit()}
				>
					<div
						tabIndex={0}
						role="button"
						className="padding-25"
					>
						<Grid container spacing={16}>
							<Grid item xs={12}>
								<form noValidate autoComplete="off">
									<Typography variant="h6" className="sub-title" gutterBottom>
										Presentation: {selectedAddEditDateTemp}
									</Typography>
									<Grid item xs={12}>
										<TextField
											id="outlined-name"
											label="Title"
											value={selectedAddEditTitle}
											margin="normal"
											// variant="filled"
											className="input-field"
											onChange={this.handleFormChange('selectedAddEditTitle')}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											id="date"
											label="Date"
											type="date"
											value={selectedAddEditDateTemp}
											InputLabelProps={{
												shrink: true,
											}}
											// variant="filled"
											className="input-field"
											onChange={this.handleFormChange('selectedAddEditDate')}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											id="filled-multiline-static"
											label="Message"
											multiline
											rows="4"
											value={selectedAddEditMessage}
											onChange={this.handleFormChange('selectedAddEditMessage')}
											margin="normal"
											className="input-field"
											// variant="filled"
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											id="outlined-name"
											label="Video Title"
											value={selectedVideoTitle}
											margin="normal"
											// variant="filled"
											className="input-field"
											onChange={this.handleFormChange('selectedVideoTitle')}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											id="outlined-name"
											label="Video Image URL"
											value={selectedVideoImgURL}
											margin="normal"
											// variant="filled"
											className="input-field"
											onChange={this.handleFormChange('selectedVideoImgURL')}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											id="outlined-name"
											label="Video ID"
											value={selectedVideoID}
											margin="normal"
											// variant="filled"
											className="input-field"
											onChange={this.handleFormChange('selectedVideoID')}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											id="outlined-name"
											label="Video ALT ID"
											value={selectedVideoAltID}
											margin="normal"
											// variant="filled"
											className="input-field"
											onChange={this.handleFormChange('selectedVideoAltID')}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											id="outlined-name"
											label="Video Speaker"
											value={selectedVideoSpeaker}
											margin="normal"
											// variant="filled"
											className="input-field"
											onChange={this.handleFormChange('selectedVideoSpeaker')}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											id="outlined-name"
											label="Video Status"
											value={selectedVideoStatus}
											margin="normal"
											// variant="filled"
											className="input-field"
											onChange={this.handleFormChange('selectedVideoStatus')}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											id="outlined-name"
											label="Video Start Time"
											value={selectedVideoStartTime}
											margin="normal"
											// variant="filled"
											className="input-field"
											onChange={this.handleFormChange('selectedVideoStartTime')}
										/>
									</Grid>
								</form>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="h6" className="sub-title" gutterBottom>
									Song List
										<IconButton aria-label="Delete" onClick={() => this.clearSelectedSongs()}>
										<DeleteIcon />
									</IconButton>
								</Typography>
								<Divider />
								<div id="song-search">
									<Select
										options={suggestions}
										onChange={this.handleSongSearch}
										className="padding-9"
									/>
								</div>
								<List>
									{selectedSongs.map((item, index) => (
										<ListItem key={item.songID}>
											<ListItemText primary={item.name} />
											<ListItemSecondaryAction>
												<IconButton aria-label="Delete" onClick={() => this.removedSelectedSong(item.songID)}>
													<DeleteIcon />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									))}
								</List>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={16}>
									<Grid item xs={12} align="right" className={"setupButtons"}>
										<Button variant="contained" onClick={() => this.save("save")}>
											Save
										</Button>
										{action === "edit" &&
											<Button variant="contained" onClick={() => this.delete()}>
												Delete
											</Button>
										}
										<Button variant="contained" onClick={() => this.closeEdit()}>
											Close
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</Drawer>
			</div>
		);
	}
}

export default PresentationsPage;

