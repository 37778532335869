import axios from 'axios';
import { API_ENDPOINT, API_ENDPOINT2 } from '../constants/enviroment';

export const PresentationsService = {
    RetrieveAll: async () => {
		return axios.get(`${API_ENDPOINT}/_getPresentations.php?c=20!15&owner=1`)
			.then(function (response) {
                return response.data;    
            })
    },
    RetrieveVideoDetails: async (id) => {
		return axios.get(`${API_ENDPOINT}/_getVideoDetails.php?c=20!15&owner=1&id=${id}`)
			.then(function (response) {
                return response.data;    
            })
    },
    SavePresentation: async (pPresentation) => {
		const options = {
			method: 'POST',
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			data: pPresentation,
			url: `${API_ENDPOINT}/_savePresentation.php?c=20!15`,
		};
		return axios(options)
		.then(function (response) {
			return response.data;
		});
    },
    SaveNotes: async (pPresentationId, pNotes) => {
        const options = {
            method: 'POST',
            // headers: { 'content-type': 'application/json' },
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: {
                "notes": JSON.stringify(pNotes),
                "id": pPresentationId,
                "owner": 1
            },
            // url: `${API_ENDPOINT2}/presentations/notes`,
            url: `${API_ENDPOINT}/_saveSermonNotes.php?c=20!15`,
        };

        return axios(options)
            .then(function (response) {
                console.log(response)
                return response;
            });
        
        // try {
        //     const response = await axios(options);
        //     return {
        //         statusCode: response.status,
        //         results: response.data.results
        //     }
        // } catch (error) {
        //     return {
        //         statusCode: error.response.status,
        //         results: error.response.data
        //     }
        // }
    },
    SaveLiveContent: async (pPresentationId, pContent) => {
        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: {
                "id": pPresentationId,
                "owner": 1,
                "content": JSON.stringify(pContent)
            },
            url: `${API_ENDPOINT}/_saveLiveContent.php?c=20!15`,
        };
        return axios(options)
            .then(function (response) {
                return response;
            });
    },
    GetLiveContent: async (pPresentationId) => {
		return axios.get(`${API_ENDPOINT}/_getLiveContent.php?c=20!15&id=${pPresentationId}`)
			.then(function (response) {
                return response.data;    
            })
    },
};

export default PresentationsService;