import axios from 'axios';
import { API_ENDPOINT, API_ENDPOINT2 } from '../constants/enviroment';

export const SongsService = {
    RetrieveAll: async () => {
        // return axios.get(`${API_ENDPOINT2}/presentations/assets`)
        return axios.get(`${API_ENDPOINT}/_getSongs.php?c=20!15&owner=1`)
			.then(function (response) {
                // return response.data.results;
                return response.data;    
            })
    },
    SaveAsset: async (pAsset) => {

        const options = {
			method: 'POST',
			data: JSON.stringify({ slides: pAsset.newSlides, id: pAsset.songID, name: pAsset.name, active: pAsset.active, slidesarray: pAsset.newSlidesArray }),
			url: `${API_ENDPOINT}/_saveAsset.php?c=20!15&owner=1`,
		};
		return axios(options)
		.then(function (response) {
			return response.data;
        });

        // const options = {
        //     method: 'POST',
        //     headers: { 'content-type': 'application/json' },
        //     data: {
        //         "slides": pAsset.newSlides,
        //         "id": pAsset.songID,
        //         "name": pAsset.name,
        //         "active": pAsset.active,
        //         "lyrics": "",
        //         "slides": pAsset.newSlidesArray,
        //         "owner": 1
        //     },
		// 	url: `${API_ENDPOINT2}/presentations/assets`,
        // };
        
        // try {
		// 	const response = await axios(options);
		// 	return {
		// 		statusCode: response.status,
		// 		results: response.data.results
		// 	}
		// } catch (error) {
		// 	return {
		// 		statusCode: error.response.status,
		// 		results: error.response.data
		// 	}
        // }
    }
};

export default SongsService;