import React, { Component } from 'react';
import { connect } from "react-redux";
import Select from 'react-select';
import './SongSearch.css';

class SongSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            suggestions: [],
            selectedOption: null
        }
    }


    componentDidMount() {
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
    }

    render() {
        const { selectedOption } = this.state;
        const suggestions = this.props.songs.map(suggestion => ({
            value: suggestion.songID,
            label: suggestion.name,
        }));

        return (
            <div id="song-search">
                <Select
                    value={selectedOption}
                    options={suggestions}
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    songs: state.hofcc.songs,
})

export default connect(mapStateToProps)(SongSearch);
