import { NAVIGATION_OCCURED, GET_SONGS, GET_PRESENTATIONS, SAVE_PRESENTATION, DELETE_PRESENTATION, SET_LIVE_PRESENTATION } from "../actionTypes";
import _ from 'lodash';

const initialState = {
    songs: [],
    presentations: [],
    livePresentation: {
        title: "",
        dateFor: "",
        message: "",
        currentSlide: 0,
        slides: [],
        history: []
    },
    activeMenu: "index"
};

export default function (state = initialState, action) {
    switch (action.type) {
        case NAVIGATION_OCCURED: {
            return {
                ...state,
                activeMenu: action.active
            };
        }
        case GET_SONGS: {
            return {
                ...state,
                songs: action.songs
            };
        }
        case GET_PRESENTATIONS: {
            return {
                ...state,
                presentations: action.presentations
            };
        }
        case SAVE_PRESENTATION: {
            const key = _.findKey(state.presentations, function(o) { return o.presentationID == action.presentation.presentationID; });
            let newState = Object.assign({}, state);         
            newState.presentations[key] = action.presentation;
            newState = newState.presentations;
            return {
                ...state,
                presentations: newState
            }
        }
        case DELETE_PRESENTATION: {
            const key = _.findKey(state.presentations, function(o) { return o.presentationID == action.presentationID; });
            let newState = Object.assign({}, state);         
            newState.presentations[key].active = "0";
            newState = newState.presentations;
            return {
                ...state,
                presentations: newState
            }
            // const key = _.findKey(state.presentations, function(o) { return o.presentationID == action.presentationID; });
            
            // // var newAttrState = state.attributes[action.userIndustry].slice()
            // // let removeIndex = newAttrState.indexOf(action.attribute)
            // // newAttrState.splice(removeIndex, 1)
            // // return Object.assign({}, state, { attributes: { [action.userIndustry]: newAttrState }} );

            
            // // const removeIndex = newState.attributes[action.industry].indexOf(action.attribute)
            // // return {
            // //     ...state,
            // //     attributes: {
            // //     ...state.attributes,
            // //     [action.industry]: [
            // //         ...state.attributes[action.industry].slice(0, removeIndex), 
            // //         ...state.attributes[action.industry].slice(removeIndex + 1)
            // //         ]
            // //     }
            // // }

            // // const key = _.findKey(state.presentations, function(o) { return o.presentationID == action.presentationID; });
            // // let newState = Object.assign({}, state.presentations);
            // // delete newState[key];
            // // const removeIndex = newState.attributes[action.industry].indexOf(action.attribute)

            // // [action.industry]: [
            // //     ...state.attributes[action.industry].slice(0, removeIndex), 
            // //     ...state.attributes[action.industry].slice(removeIndex + 1)
            // //   ]
            // // }

            // return {
            //     ...state,
            //     presentations: [
            //         ...state.presentations.slice(0, key),
            //         ...state.presentations.slice(key + 1)
            //     ]
            //     //presentations: state.presentations.map(presentation => presentation.presentationID !== action.presentationID)
            // }
        }
        case SET_LIVE_PRESENTATION: {
            return {
                ...state,
                livePresentation: action.presentation
            }
        }
        default:
            return {
                ...state
            };
    }
}
