import * as actionTypes from "./actionTypes";
import dispatch from 'redux-thunk';
import axios from 'axios';

let nextTodoId = 0;

export const setActiveMenuTab = route => (
  { type: actionTypes.NAVIGATION_OCCURED, active: route }
);

export const getSongs = () => {
  return (dispatch) => { 
    axios.get('http://worship.houseoffirechristianchurch.com/api/_getSongs.php?c=20!15&owner=1')
    .then(function (response) {
      dispatch({ 
        type: actionTypes.GET_SONGS,
        songs: response.data
      })
    })
  }
}

export const getPresentations = () => {
  return (dispatch) => { 
    axios.get('http://worship.houseoffirechristianchurch.com/api/_getPresentations.php?c=20!15&owner=1')
    .then(function (response) {
      dispatch({ 
        type: actionTypes.GET_PRESENTATIONS,
        presentations: response.data
      })
    })
  }
}

export const savePresentation = (presentation) => (
  { type: actionTypes.SAVE_PRESENTATION, presentation: presentation }
)

export const deletePresentation = (presentationID) => (
  { type: actionTypes.DELETE_PRESENTATION, presentationID: presentationID }
)

export const initializeData = () => {
  return (dispatch) => {
      dispatch(getPresentations());
      dispatch(getSongs());
  };
}

export const setLivePresentation = presentation => (
  { type: actionTypes.SET_LIVE_PRESENTATION, presentation: presentation }
);

// export function itemsFetchData(url) {
//   return (dispatch) => {
//       dispatch(itemsIsLoading(true));

//       fetch(url)
//           .then((response) => {
//               if (!response.ok) {
//                   throw Error(response.statusText);
//               }

//               dispatch(itemsIsLoading(false));

//               return response;
//           })
//           .then((response) => response.json())
//           .then((items) => dispatch(itemsFetchDataSuccess(items)))
//           .catch(() => dispatch(itemsHasErrored(true)));
//   };
// }

export const addTodo = content => ({
  type: actionTypes.ADD_TODO,
  payload: {
    id: ++nextTodoId,
    content
  }
});

export const toggleTodo = id => ({
  type: actionTypes.TOGGLE_TODO,
  payload: { id }
});