import axios from 'axios';
import { API_ENDPOINT } from '../constants/enviroment';

export const BibleService = {
    GetBooks: async () => {
        //return axios.get(`${API_ENDPOINT}/bible/booksSearch.php`)
        return axios.get(`/assets/bible/books.json`)
			.then(function (response) {
                return response.data.data;    
            })
    },
    GetChapter: async (pBook, pChapter) => {
        var book = pBook.replace(/\s+/g, '').toLowerCase();
        return axios.get(`/assets/bible/nkjv/${book}.json`)
			.then(function (response) {
                return response.data.book.chapters[parseInt(pChapter)-1];   
            })
    },
    
    GetPassage: async (pBook, pChapter, pVerses) => {
        var book = pBook.replace(/\s+/g, '').toLowerCase();

        //return axios.get(`${API_ENDPOINT}/bible/verseSearch.php?b=${pBook}&c=${pChapter}&v=${pVerses}`)
        return axios.get(`/assets/bible/nkjv/${book}.json`)
			.then(function (response) {
                var items = [];

                if (pVerses.toString().indexOf('-') > -1) {
                    pVerses = pVerses.toString().split("-");
                    for (var i = parseInt(pVerses[0]); i <= parseInt(pVerses[1]); i++) {
                        items.push({
                            text: response.data.book.chapters[parseInt(pChapter)-1].verses[i-1].text
                        })
                    }
                }
                else {
                    if (response.data.book.chapters[parseInt(pChapter)-1].verses[pVerses-1]) {
                        items.push({
                            text: response.data.book.chapters[parseInt(pChapter)-1].verses[pVerses-1].text
                        })
                    }
                }

                return items;    
            })
    }
};

export default BibleService;