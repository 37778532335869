import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';

import SongsService from '../../services/songs';

import './AssetsPage.css';

class AssetsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            assets: [],
            alphabetArray: ['#','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
            assetView: {},
            assetsViewTitle: "",
            assetsViewSlides: ""
        };
      }

	componentDidMount() {
		const self = this;

		SongsService.RetrieveAll().then(function(response) {
			self.setState({
				assets: response
			});
		});
	}
    
    handleAssetView = (asset) => {
        var slides = "";

        for (var i = 0; i < asset.lyrics.slides.length; i++) {
            slides += asset.lyrics.slides[i].details.replace( /[\r\n]+/gm, "");
            if (i < asset.lyrics.slides.length - 1) {
                slides += "\r\n\r\n"; //slides += "\r\n|\r\n";
            }
        }
        slides = slides.replace(/<br>/g, '\r\n'); // slides.replace(/<br>/g, '<br>\r\n');

        this.setState({ assetView: asset, assetsViewSlides: slides, assetsViewTitle: asset.name });
    }
    
    handleAssetsTitleChange = name => event => {
		this.setState({ assetsViewTitle: event.target.value })
    };

    handleAssetsSlidesChange = name => event => {
		this.setState({ assetsViewSlides: event.target.value })
    };
    
    saveAsset = (pAction) => {
        const self = this;
        const tempAssets = this.state.assets;
        const payload = this.state.assetView;
        payload.newSlides = this.state.assetsViewSlides;
        payload.name = this.state.assetsViewTitle;
        payload.active = pAction === "delete" ? 0 : 1;

        var assetsViewSlidesArray = payload.newSlides;
        var tempSlides = [];
        var tempSlides2 = [];
        var slideOrder = 1;
        assetsViewSlidesArray = assetsViewSlidesArray.split("|");
        for (var i = 0; i < assetsViewSlidesArray.length; i++) {
            var tempSlide = {
                "details": assetsViewSlidesArray[i],
                "order": slideOrder.toString(),
                "type": "lyrics"
            }
            var tempSlide2 = {
                "details": assetsViewSlidesArray[i].replace(/(\r\n|\n|\r)/gm,""),
                "order": slideOrder.toString(),
                "type": "lyrics"
            }
            slideOrder ++;
            tempSlides.push(tempSlide);
            tempSlides2.push(tempSlide2);
        }
        payload.newSlidesArray = JSON.stringify(tempSlides2);

        SongsService.SaveAsset(payload).then(function(response) {
            let title = "Saved";
			let text = "The asset has been saved.";
			if (pAction === "delete") {
				title = "Deleted";
				text = "The asset has been deleted.";
            }

            if (payload.songID !== "") {
                const key = _.findKey(self.state.assets, function(o) { return o.songID === payload.songID; });
                tempAssets[key].name = payload.name;
                tempAssets[key].active = payload.active;
                tempAssets[key].lyrics.slides = tempSlides;
                self.setState({ assets: tempAssets });
            }
            else {
                title = "Created";
				text = "The asset has been created.";
                const newAsset = {
                    "active": 1,
                    "lyrics": {"raw": "", "music": "", "slides": tempSlides},
                    "name": payload.name,
                    "songID": response
                }
                self.setState({ assets: [newAsset].concat(tempAssets), assetView: newAsset });
            }
            
			swal({
				title: title,
				text: text,
				icon: "success",
            })
            
            if (pAction === "delete" || pAction === "create") {
				self.closeAsset();
			}
        });
    }

    create() {
        const newAsset = {
            "active": 1,
            "lyrics": {"raw": "", "music": "", "slides": []},
            "name": "New Asset",
            "songID": ""
        }
        this.setState({
            assetsViewTitle: "New Asset Name",
            assetView: newAsset,
            assetsViewSlides: ""
		});
    }

    delete() {
		swal({
			title: "Are you sure?",
			text: "The asset will be deleted.",
			icon: "error",
			buttons: true,
			dangerMode: true,
		})
		.then((willDelete) => {
			if (willDelete) {
				this.saveAsset("delete");
				return true;
			}
			else {
				return false;
			}
		});
    }
    
    closeAsset = () => {
        this.setState({ assetView: {}, assetsViewSlides: "", assetsViewTitle: "" });
    }

    render() {
        const { alphabetArray, assets, assetView, assetsViewSlides, assetsViewTitle } = this.state;
        return (
            <div id="assets-page" className="margin-25">
                <Fab className="add-fab" onClick={() => this.create()}>
					<AddIcon/>
				</Fab>

                <Grid container spacing={16}>
                    <Grid item xs={0} sm={0} md={1}></Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <List className="list" subheader={<li />}>
                            {alphabetArray.map(sectionId => (
                                <li key={`section-${sectionId}`} className="list-section">
                                    <ul className="ul">
                                        <ListSubheader className="subheader">{`${sectionId}`}</ListSubheader>
                                        
                                        { assets
                                            .filter(function (asset) {
                                                if (sectionId === '#') {
                                                    return Number.isInteger(parseInt(asset.name.charAt(0)));
                                                }
                                                return asset.name.charAt(0) === sectionId;
                                            })
                                            .filter(function (asset) {
                                                return parseInt(asset.active) === 1
                                            })
                                            .map(item => (
                                                <ListItem key={`item-${item.songID}`}>
                                                    <ListItemText primary={`${item.name}`} onClick={() => this.handleAssetView(item)}/>
                                                </ListItem>
                                            ))
                                        }

                                    </ul>
                                </li>
                            ))}
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        {assetView.name &&
                            <div className="asset-details">
                                <Grid item xs={12}><Typography component="h2" variant="display1">{assetView.name}</Typography></Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-name"
                                        label="Title"
                                        value={assetsViewTitle}
                                        margin="normal"
                                        // variant="filled"
                                        className="input-field"
                                        onChange={this.handleAssetsTitleChange('assetsViewTitle')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="filled-multiline-static"
                                        label="Slides"
                                        multiline
                                        rows="25"
                                        value={assetsViewSlides}
                                        onChange={this.handleAssetsSlidesChange("assetsViewSlides")}
                                        margin="normal"
                                        className="input-field"
                                        // variant="filled"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button size="small" onClick={() => this.saveAsset("save")}>Save</Button>
                                    {assetView.songID !== "" &&
                                        <Button size="small" onClick={() => this.delete()}>Delete</Button>
                                    }
                                    <Button size="small" onClick={() => this.closeAsset()}>Close</Button>
                                </Grid>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={0} sm={0} md={1}></Grid>
                </Grid>
                    
            </div>

        );
    }
}

export default AssetsPage;