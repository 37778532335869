import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'

import {Provider} from 'react-redux';
import { initStateWithPrevTab } from 'redux-state-sync';
import store from './redux/store';

import './index.css';
import registerServiceWorker from './registerServiceWorker';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

// import Routes from './modules/routes';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas }  from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import Navigation from './components/MenuNavigation/MenuNavigation';

initStateWithPrevTab(store);
library.add(fab, fas)

require('dotenv').config();

const THEME = createMuiTheme({
    palette: {
        primary: {
            main: '#282828'
        }
    },
    typography: {
        "fontFamily": "\"NewhouseDTPro-Light\", sans-serif",
        "fontSize": 14,
    }
});

ReactDOM.render((
    <MuiThemeProvider theme={THEME}>
        <Provider store={store}>
            <Router>
                <Navigation />
            </Router>
        </Provider>
    </MuiThemeProvider>
), document.getElementById('root'))

registerServiceWorker();
