import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import '../PresentationPage.css';
import './LivePage.css';

import queryString from 'query-string';
import { LIVE_PRESENTATION_ENDPOINT } from '../../../constants/enviroment';
import io from 'socket.io-client';
import PresentationsService from '../../../services/presentations.js';

const socket = io(LIVE_PRESENTATION_ENDPOINT);

class LivePage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			songs: this.props.songs,
			presentationId: this.props.match.params.presentationid,
			delay: this.props.match.params.delay ? this.props.match.params.delay : 1000,
			currentPresentation: {},
			insertedSongSlides: [],
			currentSlide: 0,
			showSlides: true,
			settings: {
				theme: 'green-screen-theme',
				background: 'radial-gradient(#00b140, #00b140)',
				backgroundImage: '',
				textAlign: 'center',
				fontColor: '',
				fontSizes: {
					main: '8rem',
					date: '4rem',
					message: '4rem',
					songTitle: '6.5rem',
					lyrics: '5.6rem',
					verse: '2.5rem',
					reference: '2.5rem'
				},
				animation: "none"
			},
			currentSlideContent: {
				type: '',
				order: 0,
				details: {
					date: '',
					message: '',
					title: ''
				}
			},
			insertedContent: {
				type: 'custom',
				content: {
					main: '',
					sub: ''
				}
			},
			liveContent: {
				type: 'custom',
				content: {}
			},
			time: this.props.time,
			socketRoom: this.props.match.params.presentationid
		}
	}

	componentDidMount() {
		const UrlQueryStrings = this.props.location.search;
		const queryValues = queryString.parse(UrlQueryStrings);

		document.title = "HOFCC | Live";

		socket.emit('joined room', this.state.socketRoom);

		const self = this;
		document.getElementsByTagName("html")[0].style.fontSize = "15px";
		document.getElementsByTagName("header")[0].style.display = "none";
		
		if (typeof queryValues.socket !== "undefined" && queryValues.socket == "e") {
			var delay = parseInt(self.state.delay) + 500;
			setInterval(()=>{
				self.setState({time:this.state.time});
				PresentationsService.GetLiveContent(self.state.presentationId).then(function(response) {
					var tempTimeout = self.props.match.params.delay;
					if (!tempTimeout) {
						tempTimeout = 0;
					}
					else {
						tempTimeout = parseInt(tempTimeout);
					}
					setTimeout(function() {
						if (response != "") {
							self.setState({ liveContent: response });
						}
						else {
							self.setState({ liveContent: { type: "custom", content: {} }});
						}
					}, tempTimeout)
				});
			} , delay);
		}
		else {
			socket.on('connected', (content) => {
				if (content) {
					self.setState({ liveContent: content });
				}
			});

			socket.on('content update', (content) => {
				var tempTimeout = self.props.match.params.delay;
				if (!tempTimeout) {
					tempTimeout = 0;
				}
				else {
					tempTimeout = parseInt(tempTimeout);
				}
				setTimeout(function() {
					if (content != "") {
						self.setState({ liveContent: content });
					}
					else {
						self.setState({ liveContent: { type: "custom", content: {} }});
					}
				}, tempTimeout)
			});
		}
	}

	////////////////////////////////////////////////////////////////////////////////////////////////////

	render() {
		const { liveContent, showSlides, settings, insertedContent } = this.state;

		return (
			<div id="presentation-page" className={settings.theme}>
				<img className="logo" alt="logo" src="/assets/images/Logo-White.svg" />

				<div className={"show slides-lyrics"}>
					{liveContent.type === "song" && liveContent.content.type === "intro" &&
						<div>
							<Typography className="title" style={{ fontSize: settings.fontSizes.main, color: settings.fontColor, textAlign: settings.textAlign === "" ? "left" : settings.textAlign }} align="center">{liveContent.content.details.title}</Typography>
							<Typography className="date" style={{ fontSize: settings.fontSizes.date, color: settings.fontColor, textAlign: settings.textAlign === "" ? "left" : settings.textAlign }} align="center">{liveContent.content.details.date}</Typography>
							<Typography className="message" style={{ fontSize: settings.fontSizes.message, color: settings.fontColor, textAlign: settings.textAlign === "" ? "left" : settings.textAlign }} align="center">{liveContent.content.details.message}</Typography>
						</div>
					}

					{liveContent.type === "song" && liveContent.content.type === "title" &&
						<div>
							<Typography className="songTitle" style={{ fontSize: settings.fontSizes.songTitle, color: settings.fontColor, textAlign: settings.textAlign === "" ? "left" : settings.textAlign }} align="center" dangerouslySetInnerHTML={{ __html: liveContent.content.details }}></Typography>
						</div>
					}

					{liveContent.type === "song" && liveContent.content.type === "lyrics" &&
						<div>
							<Typography className="lyrics" style={{ fontSize: settings.fontSizes.lyrics, color: settings.fontColor, textAlign: settings.textAlign === "" ? "left" : settings.textAlign }} align="left" dangerouslySetInnerHTML={{ __html: liveContent.content.details }}></Typography>
						</div>
					}


					{liveContent.type === "custom" && liveContent.content.type === "verse" &&
						<div>
							<Typography className="reference" style={{ fontSize: settings.fontSizes.reference, color: settings.fontColor }} align="center" dangerouslySetInnerHTML={{ __html: liveContent.content.content.sub }}></Typography>
							<Typography className="verse" style={{ fontSize: settings.fontSizes.verse, color: settings.fontColor }} align="center" dangerouslySetInnerHTML={{ __html: liveContent.content.content.main }}></Typography>
						</div>
					}

					{liveContent.type === "custom" && liveContent.content.type === "image" &&
						<div>
							<img className="image" alt="slide note" src={liveContent.content.content.main}></img>
						</div>
					}


					{liveContent.type === "custom" && liveContent.content.type === "video" &&
						<div className="video">
							<iframe title="noteFrame" src={liveContent.content.content.main} frameBorder='0' allowfullscreen="allowfullscreen"></iframe>
						</div>
					}

					{liveContent.type === "custom" && liveContent.content.type === "custom" &&
						<div className="custom" dangerouslySetInnerHTML={{ __html: liveContent.content.content.main }} style={{ fontSize: settings.fontSizes.verse, color: settings.fontColor }}></div>
					}
				</div>
			</div>
		);
	}
}

LivePage.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default LivePage;