export const NAVIGATION_OCCURED = "NAVIGATION_OCCURED";
export const GET_SONGS = "GET_SONGS";
export const GET_PRESENTATIONS = "GET_PRESENTATIONS";
export const SAVE_PRESENTATION = "SAVE_PRESENTATION";
export const DELETE_PRESENTATION = "DELETE_PRESENTATION";

export const SET_LIVE_PRESENTATION = "SET_LIVE_PRESENTATION";


export const ADD_TODO = "ADD_TODO";
export const TOGGLE_TODO = "TOGGLE_TODO";