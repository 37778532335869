import React, { Component } from 'react';
import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import FormatPaintIcon from '@material-ui/icons/FormatPaint';
import DeleteIcon from '@material-ui/icons/Delete';
import ToggleOffIcon from '@material-ui/icons/ToggleOff'
import TitleIcon from '@material-ui/icons/Title'
import SaveIcon from '@material-ui/icons/Save'
import RefreshIcon from '@material-ui/icons/Refresh'
import FastForwardIcon from '@material-ui/icons/FastForward'
import MusicNoteIcon from '@material-ui/icons/MusicNote'
import QueueMusicIcon from '@material-ui/icons/QueueMusic'
import ColorLensIcon from '@material-ui/icons/ColorLens'
import FormatSizeIcon from '@material-ui/icons/FormatSize'
import SearchIcon from '@material-ui/icons/Search';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import VideoCamIcon from '@material-ui/icons/Videocam';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CodeIcon from '@material-ui/icons/Code';
// import UpdateIcon from '@material-ui/icons/Update';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import CollectionsIcon from '@material-ui/icons/Collections';

import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BroadcastChannel from 'broadcast-channel';
import Select from 'react-select';
import { SketchPicker } from 'react-color';
import swal from 'sweetalert';
import _ from 'lodash';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import './ControllerPage.css';
import { ListItemText } from '@material-ui/core';

import PresentationsService from '../../../services/presentations.js';
import BibleService from '../../../services/bible.js';
import SongsService from '../../../services/songs'; 

import { LIVE_PRESENTATION_ENDPOINT } from '../../../constants/enviroment';

import io from 'socket.io-client';
const socket = io(LIVE_PRESENTATION_ENDPOINT);

const options = {
	type: 'localstorage', // (optional) enforce a type, oneOf['native', 'idb', 'localstorage', 'node']
};

const change_theme = new BroadcastChannel('change-theme', options);
const change_text_size = new BroadcastChannel('change-text-size', options);
const change_text_color = new BroadcastChannel('change-text-color', options);
const display_slides = new BroadcastChannel('display-slides', options);
const channel_slide_change = new BroadcastChannel('slide-change', options);
const insert_content = new BroadcastChannel('insert-content', options);
const change_animation = new BroadcastChannel('change-animation', options);
const change_background_image = new BroadcastChannel('change-background-image', options);
const change_text_alignment = new BroadcastChannel('change-text-alignment', options);

class ControllerPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tabOption: 0,
			showSlides: true,
			presentationId: this.props.match.params.presentationid,
			currentPresentation: {},
			//songs: localStorage.getItem("songs") !== "undefined" ? JSON.parse(localStorage.getItem("songs")) : [],
			songs: [],
			books: [],
			textAlignmentSelect: [
				{ value: "left", label: "left" },
				{ value: "center", label: "center" },
				{ value: "right", label: "right" }
			],
			themeSelect: [
				{ value: "blackout-theme", label: "blackout" },
				{ value: "whiteout-theme", label: "whiteout" },
				{ value: "light-theme", label: "light" },
				{ value: "dark-theme", label: "dark" },
				{ value: "purple-pink-theme", label: "purple-pink" },
				{ value: "blue-theme", label: "blue" },
				{ value: "hofcc-theme", label: "hofcc" },
				{ value: "hofcc-ani-theme", label: "hofcc animated" }
			],
			animationSelect: [
				{ value: "none", label: "none" },

				{ value: "bounce", label: "bounce" },
				{ value: "bounceIn", label: "bounceIn" },
				{ value: "bounceInDown", label: "bounceInDown" },
				{ value: "bounceInLeft", label: "bounceInLeft" },
				{ value: "bounceInRight", label: "bounceInRight" },
				{ value: "bounceInUp", label: "bounceInUp" },

				// {value: "bounceOut", label: "bounceOut"}, 
				// {value: "bounceOutDown", label: "bounceOutDown"}, 
				// {value: "bounceOutLeft", label: "bounceOutLeft"}, 
				// {value: "bounceOutRight", label: "bounceOutRight"}, 
				// {value: "bounceOutUp", label: "bounceOutUp"}, 

				{ value: "fadeIn", label: "fadeIn" },
				{ value: "fadeInDown", label: "fadeInDown" },
				{ value: "fadeInDownBig", label: "fadeInDownBig" },
				{ value: "fadeInLeft", label: "fadeInLeft" },
				{ value: "fadeInLeftBig", label: "fadeInLeftBig" },
				{ value: "fadeInRight", label: "fadeInRight" },
				{ value: "fadeInRightBig", label: "fadeInRightBig" },
				{ value: "fadeInUp", label: "fadeInUp" },
				{ value: "fadeInUpBig", label: "fadeInUpBig" },

				// {value: "fadeOut", label: "fadeOut"}, 
				// {value: "fadeOutDown", label: "fadeOutDown"}, 
				// {value: "fadeOutDownBig", label: "fadeOutDownBig"}, 
				// {value: "fadeOutLeft", label: "fadeOutLeft"}, 
				// {value: "fadeOutLeftBig", label: "fadeOutLeftBig"}, 
				// {value: "fadeOutRight", label: "fadeOutRight"}, 
				// {value: "fadeOutRightBig", label: "fadeOutRightBig"}, 
				// {value: "fadeOutUp", label: "fadeOutUp"}, 
				// {value: "fadeOutUpBig", label: "fadeOutUpBig"}, 

				{ value: "flash", label: "flash" },
				{ value: "flip", label: "flip" },
				{ value: "flipInX", label: "flipInX" },
				{ value: "flipInY", label: "flipInY" },
				// {value: "flipOutX", label: "flipOutX"}, 
				// {value: "flipOutY", label: "flipOutY"}, 

				{ value: "heartBeat", label: "heartBeat" },
				{ value: "hinge", label: "hinge" },
				{ value: "jackInTheBox", label: "jackInTheBox" },
				{ value: "jello", label: "jello" },


				{ value: "lightSpeedIn", label: "lightSpeedIn" },
				//{value: "lightSpeedOut", label: "lightSpeedOut"}, 
				{ value: "pulse", label: "pulse" },

				{ value: "rollIn", label: "rollIn" },
				//{value: "rollOut", label: "rollOut"}, 

				{ value: "rotateIn", label: "rotateIn" },
				{ value: "rotateInDownLeft", label: "rotateInDownLeft" },
				{ value: "rotateInDownRight", label: "rotateInDownRight" },
				{ value: "rotateInUpLeft", label: "rotateInUpLeft" },
				{ value: "rotateInUpRight", label: "rotateInUpRight" },

				// {value: "rotateOut", label: "rotateOut"}, 
				// {value: "rotateOutDownLeft", label: "rotateOutDownLeft"}, 
				// {value: "rotateOutDownRight", label: "rotateOutDownRight"}, 
				// {value: "rotateOutUpLeft", label: "rotateOutUpLeft"}, 
				// {value: "rotateOutUpRight", label: "rotateOutUpRight"}, 
				{ value: "rubberBand", label: "rubberBand" },

				{ value: "shake", label: "shake" },

				{ value: "slideInUp", label: "slideInUp" },
				{ value: "slideInDown", label: "slideInDown" },
				{ value: "slideInLeft", label: "slideInLeft" },
				{ value: "slideInRight", label: "slideInRight" },

				// {value: "slideOutUp", label: "slideOutUp"}, 
				// {value: "slideOutDown", label: "slideOutDown"}, 
				// {value: "slideOutLeft", label: "slideOutLeft"}, 
				// {value: "slideOutRight", label: "slideOutRight"}, 
				{ value: "swing", label: "swing" },

				{ value: "tada", label: "tada" },

				{ value: "wobble", label: "wobble" },

				{ value: "zoomIn", label: "zoomIn" },
				{ value: "zoomInDown", label: "zoomInDown" },
				{ value: "zoomInLeft", label: "zoomInLeft" },
				{ value: "zoomInRight", label: "zoomInRight" },
				{ value: "zoomInUp", label: "zoomInUp" },

				// {value: "zoomOut", label: "zoomOut"}, 
				// {value: "zoomOutDown", label: "zoomOutDown"}, 
				// {value: "zoomOutLeft", label: "zoomOutLeft"}, 
				// {value: "zoomOutRight", label: "zoomOutRight"}, 
				// {value: "zoomOutUp", label: "zoomOutUp"},

			],
			backgroundImage: "",
			selectedTheme: { value: "blackout-theme", label: "blackout" },
			selectedAnimation: { value: "none", label: "none" },
			booksSelect: [],
			chaptersSelect: [],
			songsSelect: [],

			selectedSongSlides: [],
			selectedBook: "",
			selectedBookValue: {},
			selectedBookChapter: 1,
			selectedBookChapterValue: {},
			selectedBookVerses: 1,

			slidesHistory: [],

			editorState: EditorState.createEmpty(),

			fontSize: 15,
			textAlign: "",
			openFontSize: null,
			anchorElFontSize: null,
			anchorElColorPicker: null,
			openColorPicker: false,
			anchorElTheme: null,
			openTheme: false,
			anchorElAnimation: null,
			openAnimation: false,
			anchorElBackgroundImage: null,
			openBackgroundImage: false,
			anchorElTextAlignment: null,
			openTextAlignment: false,
			updateNoteIndex: ""
		}
	}

	componentDidMount() {
		document.title = "HOFCC | Controller";
		document.getElementsByTagName("header")[0].style.display = "none";

		this.getPresentation();

		socket.emit('joined room', this.state.presentationId);

		const self = this;
		BibleService.GetBooks().then(function(response) {
			const booksSelect = response.map(book => ({
				value: book.name,
				label: book.name
			}));
			self.setState({ books: response, booksSelect: booksSelect });
		});

		this.getSongs();

		if (localStorage.getItem("presentationID") !== this.state.presentationId) {
			localStorage.setItem("presentationID", this.state.presentationId);
			localStorage.setItem("history", "[]");
		}

		socket.on('content update external', (content) => {
			console.log("content", content)
			
			const action = content.action;
			const slide = content.content;

			if (action == "changeSlide") {
				this.changeSlide(slide, content.index, "incoming");
			}
			else if (action == "insertVerse") {
				console.log(slide.content.content.main)
				const contentBlock = htmlToDraft(slide.content.content.main);
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
				const editorState = EditorState.createWithContent(contentState)

				let book = "";
				let chapter = "";
				let verse = "";
				let bookObject = {};
				let chapterArray = [];
				let chapterObject = {};
				
				const reference = slide.content.content.sub;
				const referenceArray = reference.split(" ");

				book = referenceArray[0].length === 1 ? referenceArray[0] + " " + referenceArray[1] : referenceArray[0];
				bookObject = { value: book, label: book };

				this.handleBookSelection(bookObject);

				chapter = referenceArray[0].length === 1 ? referenceArray[2] : referenceArray[1];
				chapterArray = chapter.split(":")[0];
				chapterObject = { value: chapterArray, label: chapterArray };

				verse = reference.split(":")[1];

				this.setState({
					editorState,
					selectedBook: book,
					selectedBookValue: bookObject,
					selectedBookChapter: chapterArray,
					selectedBookChapterValue: chapterObject,
					selectedBookVerses: verse,
				});

				this.insertVerse("insert", "incoming");
				

			}
			else if (action == "insertHistory") {
				this.insertHistory(slide, content.index, content.insertType, "incoming");
			}
			else if (action == "insertSong") {
				const song = JSON.parse(slide);
				const songTitle = { "details": song.label, "order": 0, "type": "title" };
				this.setState({ selectedSongSlides: [songTitle].concat(song.value) });
				this.insertSong("incoming");
			}
			else if (action == "saveSermonNotes") {
				this.saveSermonNotes();
			}
		});
	}

	onEditorStateChange = (editorState) => {
		this.setState({
			editorState,
		});
	};

	handleSongSearch = (song) => {
		const songTitle = { "details": song.label, "order": 0, "type": "title" };
		this.setState({ selectedSongSlides: [songTitle].concat(song.value) });
	}

	handleBookSelection = (pBook) => {
		const selectedBook = _.filter(this.state.books, function (b) {
			return b.name === pBook.value;
		});
		
		var chaptersSelect = [];
		for (var i = 1; i <= selectedBook[0].chapters; i++) {
			chaptersSelect.push({value: i, label: i});
		}

		// const chaptersSelect = selectedBook[0].chapters.map(chapter => ({
		// 	value: chapter.chapter,
		// 	label: chapter.chapter
		// }));

		this.setState({ selectedBook: pBook.value, selectedBookValue: pBook, chaptersSelect: chaptersSelect });
	}

	handleChapterSelection = (pChapter) => {
		this.setState({ selectedBookChapter: pChapter.value, selectedBookChapterValue: pChapter });
	}

	handleVerseSelection = event => {
		this.setState({ selectedBookVerses: event.target.value })
	};

	handleTabChange = (event, newValue) => {
		this.setState({ tabOption: newValue })
	}

	handleFontSizeToggle = (event) => {
		const { currentTarget } = event;
		this.setState(state => ({
			anchorElFontSize: currentTarget,
			openFontSize: !state.openFontSize,
			openColorPicker: false,
			openTheme: false,
			openAnimation: false,
			openBackgroundImage: false,
			openTextAlignment: false,
		}));
	}

	handleFontSizeChange = event => {
		this.setState({ fontSize: event.target.value });
		change_text_size.postMessage(event.target.value);
	}

	handleColorChangeToggle = (event) => {
		const { currentTarget } = event;
		this.setState(state => ({
			anchorElColorPicker: currentTarget,
			openColorPicker: !state.openColorPicker,
			openFontSize: false,
			openTheme: false,
			openAnimation: false,
			openBackgroundImage: false,
			openTextAlignment: false,
		}));
	}

	handleColorChangeComplete = (color, event) => {
		change_text_color.postMessage(color.hex);
	};

	handleThemeToggle = (event) => {
		const { currentTarget } = event;
		this.setState(state => ({
			anchorElTheme: currentTarget,
			openTheme: !state.openTheme,
			openFontSize: false,
			openColorPicker: false,
			openAnimation: false,
			openBackgroundImage: false,
			openTextAlignment: false,
		}));
	}
	handleThemeChange = (pTheme) => {
		change_theme.postMessage(pTheme);
		this.setState({ selectedTheme: pTheme });
	}
	handleAnimationToggle = (event) => {
		const { currentTarget } = event;
		this.setState(state => ({
			anchorElAnimation: currentTarget,
			openAnimation: !state.openAnimation,
			openFontSize: false,
			openColorPicker: false,
			openTheme: false,
			openBackgroundImage: false,
			openTextAlignment: false,
		}));
	}
	handleAnimationChange = (pAnimation) => {
		change_animation.postMessage(pAnimation);
		this.setState({ selectedAnimation: pAnimation })
	}

	handleBackgroundImageToggle = (event) => {
		const { currentTarget } = event;
		this.setState(state => ({
			anchorElBackgroundImage: currentTarget,
			openBackgroundImage: !state.openBackgroundImage,
			openFontSize: false,
			openColorPicker: false,
			openTheme: false,
			openAnimation: false,
			openTextAlignment: false,
		}));
	}

	handleBackgroundImageChange = (pImage) => {
		change_background_image.postMessage(pImage);
	}

	handleBackgroundImageInput = () => event => {
		this.setState({ backgroundImage: event.target.value });
	};

	handleTextAlignmentToggle = (event) => {
		const { currentTarget } = event;
		this.setState(state => ({
			anchorElTextAlignment: currentTarget,
			openTextAlignment: !state.openTextAlignment,
			openFontSize: false,
			openColorPicker: false,
			openTheme: false,
			openAnimation: false,
			openBackgroundImage: false,
		}));
	}

	handleTextAlignmentChange = (pAlign) => {
		change_text_alignment.postMessage(pAlign);
		this.setState({ textAlign: pAlign })
	}

	handleRefreshRequest = () => {
		this.getPresentation();
	}
	////////////////////////////////////////////////////////////////////////////////////////////////////

	displaySlides = (value) => {
		this.setState({ showSlides: !value, tabOption: value ? 1 : 0 });
		display_slides.postMessage(!value);
	}

	changeSlide = (slide, index, pDirection) => {
		if (document.getElementById("slides-list")) {
			for (var i = 0; i < document.getElementById("slides-list").getElementsByClassName("item").length; i++) {
				document.getElementById("slides-list").getElementsByClassName("item")[i].classList.remove("active");
			}
			document.getElementById("slide-" + index).classList.add("active");

			channel_slide_change.postMessage(slide);

			this.saveLiveContent({ "type": "song", "content": slide});

			if (pDirection == "outgoing") {
				socket.emit('content main to external', { action: "changeSlide", content: slide, room: this.state.presentationId, index: index });
			}
		}
	}

	insertSong = (pDirection) => {
		this.setState({
			currentPresentation: {
				...this.state.currentPresentation,
				slides: [
					...this.state.currentPresentation.slides, ...this.state.selectedSongSlides
				]
			}
		})

		if (pDirection == "outgoing") {
			socket.emit('content main to external', { action: "insertSong", content: JSON.stringify(this.state.selectedSongSlides), room: this.state.presentationId});
		}
	}

	insertVerse = (pAction, pDirection) => {
		const editor_content = this.getEditorContent("html");
		const { selectedBook, selectedBookChapter, selectedBookVerses } = this.state;
		const content = {
			type: 'verse',
			content: {
				main: editor_content,
				sub: selectedBook + ' ' + selectedBookChapter + ':' + selectedBookVerses
			}
		};

		this.saveSlideHistory(content);
		
		if (pAction === "insert") { 
			insert_content.postMessage(content);
			this.saveLiveContent({ "type": "custom", "content": content});
		}

		this.getVerse("next");

		if (pDirection == "outgoing") {
			socket.emit('content main to external', { action: "insertVerse", content: content, room: this.state.presentationId });
		}
	}

	insertCustom = (pAction) => {
		const editor_content = this.getEditorContent("html");
		const content = {
			type: 'custom',
			content: {
				main: editor_content,
				sub: ""
			}
		};
		this.saveSlideHistory(content);
		if (pAction === "insert") { 
			insert_content.postMessage(content);
			this.saveLiveContent({ "type": "custom", "content": content});
		}
	}

	insertImage = (pAction) => {
		const editor_content = this.getEditorContent("text");
		const content = {
			type: 'image',
			content: {
				main: editor_content,
				sub: ""
			}
		};
		this.saveSlideHistory(content);
		if (pAction === "insert") { 
			insert_content.postMessage(content);
			this.saveLiveContent({ "type": "custom", "content": content});
		}
	}

	insertVideo = (pAction) => {
		const editor_content = this.getEditorContent("text");
		const content = {
			type: 'video',
			content: {
				main: editor_content,
				sub: ""
			}
		};
		this.saveSlideHistory(content);
		if (pAction === "insert") { 
			insert_content.postMessage(content);
			this.saveLiveContent({ "type": "custom", "content": content});
		}
	}

	insertHistory = (slide, index, action, pDirection) => {
		if (document.getElementById("history-list")) {
			for (var i = 0; i < document.getElementById("history-list").getElementsByClassName("item").length; i++) {
				document.getElementById("history-list").getElementsByClassName("item")[i].classList.remove("active");
			}

			document.getElementById("history-" + index).classList.add("active");

			const contentBlock = htmlToDraft(slide.content.main);
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			const editorState = EditorState.createWithContent(contentState);

			let book = "";
			let chapter = "";
			let verse = "";
			let bookObject = {};
			let chapterArray = [];
			let chapterObject = {};
			if (slide.content.hasOwnProperty("sub") && slide.content.sub !== "") {
				const reference = slide.content.sub;
				const referenceArray = reference.split(" ");

				// book = referenceArray[0].length === 1 ? referenceArray[0] + " " + referenceArray[1] : referenceArray[0];

				book = referenceArray[0];
				if (referenceArray.length == 3) {
					book = referenceArray[0] + " " + referenceArray[1];
				}
				else if (referenceArray.length > 3) {
					book = referenceArray[0] + " " + referenceArray[1] + " " + referenceArray[2];
				}

				bookObject = { value: book, label: book };

				this.handleBookSelection(bookObject);

				chapter = referenceArray[0].length === 1 ? referenceArray[2] : referenceArray[1];
				chapterArray = chapter.split(":")[0];
				chapterObject = { value: chapterArray, label: chapterArray };

				verse = reference.split(":")[1];
			}

			this.setState({
				editorState,
				selectedBook: book,
				selectedBookValue: bookObject,
				selectedBookChapter: chapterArray,
				selectedBookChapterValue: chapterObject,
				selectedBookVerses: verse,
			});

			if (action === "update") {
				this.setState({ updateNoteIndex: index });
			}
			else {
				this.setState({ updateNoteIndex: "" });
			}
			if (action === "insert") {
				insert_content.postMessage(slide);
				this.saveLiveContent({ "type": "custom", "content": slide});
			}

			if (pDirection == "outgoing") {
				socket.emit('content main to external', { action: "insertHistory", content: slide, room: this.state.presentationId, index: index, insertType: action});

			}
		}
	}

	viewResource = (link) => {
		window.open(link, '_blank');
	}
	////////////////////////////////////////////////////////////////////////////////////////////////////
	saveSlideHistory = (slide) => {
		this.setState({ slidesHistory: [...this.state.slidesHistory, slide] })
		localStorage.setItem("history", JSON.stringify([...this.state.slidesHistory, slide]));
	}

	getEditorContent = (type) => {
		if (type === "text") {
			return convertToRaw(this.state.editorState.getCurrentContent()).blocks[0].text;
		}
		return draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
	}

	////////////////////////////////////////////////////////////////////////////////////////////////////

	loadHistory = () => {
		if (this.state.currentPresentation.notes) {
			this.setState({ slidesHistory: this.state.currentPresentation.notes });
			localStorage.setItem("history", JSON.stringify(this.state.currentPresentation.notes));
		}
		else {
			if (localStorage.getItem("history") !== "undefined") {
				if (localStorage.getItem("history") !== "" && localStorage.getItem("history") !== null) {
					const history = JSON.parse(localStorage.getItem("history"));
					if (history.length > 0) {
						this.setState({ slidesHistory: history });
					}
				}
			}
		}
	}

	deleteHistory = (pIndex) => {
		const self = this;
		swal({
			title: "Are you sure?",
			text: "The item(s) will be deleted.",
			icon: "error",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {

					if (localStorage.getItem("history") !== "undefined") {
						if (pIndex === "all") {
							self.setState({ slidesHistory: [] })
							localStorage.setItem("history", []);
						}
						else {
							const updatedHistory = _.filter(self.state.slidesHistory, function (o, index) {
								return index !== pIndex;
							});

							self.setState({ slidesHistory: updatedHistory });
							localStorage.setItem("history", JSON.stringify(updatedHistory));
						}
					}

					return true;
				}
				else {
					return false;
				}
			});

	}


	updateNote = () => {
		const tempHistory = this.state.slidesHistory;
		const { updateNoteIndex } = this.state;
		let editor_content = this.getEditorContent("html");
		var content = {};

		const slide = _.filter(this.state.slidesHistory, function (o, index) {
			return index === updateNoteIndex;
		});

		if (slide[0].type === "verse") {
			const { selectedBook, selectedBookChapter, selectedBookVerses } = this.state;
			content = {
				main: editor_content,
				sub: selectedBook + ' ' + selectedBookChapter + ':' + selectedBookVerses
			};
		}
		else {
			if (slide[0].type !== "custom") {
				editor_content = this.getEditorContent("text");
			}
			content = {
				main: editor_content,
				sub: ""
			};
		}

		swal({
			title: "Updated",
			text: "The sermon note have been updated.",
			icon: "success",
		})
		tempHistory[updateNoteIndex].content = content;
		this.setState({ slidesHistory: tempHistory })
		localStorage.setItem("history", JSON.stringify(tempHistory));

		this.setState({ updatGetPresentationseNoteIndex: "" });

		socket.emit('content main to external', { action: "updateNote", content: content, room: this.state.presentationId, index: updateNoteIndex});

	}

	getPresentation = async () => {
		const self = this;

		PresentationsService.RetrieveAll().then(function(response) {
			let currentPresentation;
			if (self.state.presentationId != 0) {
				currentPresentation = _.find(response, { 'presentationID': self.state.presentationId });
			}
			else {
				currentPresentation = response[0];
			}
			localStorage.setItem("presentation", JSON.stringify(currentPresentation));
			self.setState({
				currentPresentation: currentPresentation
			});

			self.loadHistory();
		});
	}

	getVerse = (pAction) => {
		const { selectedBook, selectedBookChapter, selectedBookVerses } = this.state;

		let selectedVerses = selectedBookVerses;
		if (pAction === "next") {
			if (selectedVerses.toString().indexOf('-') > -1) {
				selectedVerses = selectedVerses.toString().split("-")[1];
			}

			selectedVerses++;
			this.setState({ selectedBookVerses: selectedVerses });
		}
		const self = this;

		BibleService.GetPassage(selectedBook, selectedBookChapter, selectedVerses).then(function(response) {
			if (response.length > 0) {
				var verse = "";
				for (var i = 0; i < response.length; i++) {
					verse += response[i].text + " ";
				}
				verse = verse.replace(/\*/gi, "").replace(/\u2018/gi, "'").replace(/\u2019/gi, "'").replace(/\u201c/gi, '"').replace(/\u201d/gi, '"').replace(/\u2014/gi, '--');
				document.getElementById("tempVerseDiv").innerHTML = verse;
				const contentBlock = htmlToDraft(document.getElementById("tempVerseDiv").textContent);
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
				const editorState = EditorState.createWithContent(contentState);
				self.setState({
					editorState,
				});
			}
			// if (response.search.result.passages.length > 0) {
			// 	const verse = response.search.result.passages[0].text.replace(/\*/gi, "").replace(/\u2018/gi, "'").replace(/\u2019/gi, "'").replace(/\u201c/gi, '"').replace(/\u201d/gi, '"').replace(/\u2014/gi, '--');

			// 	document.getElementById("tempVerseDiv").innerHTML = verse;

			// 	// if (document.getElementById("tempVerseDiv").getElementsByTagName("sup").length > 0) {
			// 	// 	document.getElementById("tempVerseDiv").getElementsByTagName("sup")[0].parentNode.removeChild(document.getElementById("tempVerseDiv").getElementsByTagName("sup")[0]);
			// 	// }

			// 	// if (document.getElementById("tempVerseDiv").getElementsByClassName("v").length > 0) {
			// 	// 	for (var i = 0; i < document.getElementById("tempVerseDiv").getElementsByClassName("v").length; i++) {
			// 	// 		document.getElementById("tempVerseDiv").getElementsByClassName("v")[i].innerHTML = "&nbsp;";
			// 	// 	}
			// 	// }
			// 	// if (document.getElementById("tempVerseDiv").getElementsByTagName("h3").length > 0) {
			// 	// 	for (var i = 0; i < document.getElementById("tempVerseDiv").getElementsByTagName("h3").length; i++) {
			// 	// 		document.getElementById("tempVerseDiv").getElementsByTagName("h3")[i].parentNode.removeChild(document.getElementById("tempVerseDiv").getElementsByTagName("h3")[i]);
			// 	// 	}
			// 	// }

			// 	const contentBlock = htmlToDraft(document.getElementById("tempVerseDiv").textContent);
			// 	const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			// 	const editorState = EditorState.createWithContent(contentState);
			// 	self.setState({
			// 		editorState,
			// 	});
			// }
		})
	}

	getSongs = async () => {
		const tempSelectSong = _.filter(this.state.songs, function (s) {
			return s.lyrics.slides.length > 1;
		});

		const songsSelect = tempSelectSong.map(song => ({
			value: song.lyrics.slides,
			label: song.name
		}));
		this.setState({ songsSelect: songsSelect });

		const self = this;
		SongsService.RetrieveAll().then(function(response) {
			self.setState({ songs: response });
			localStorage.setItem("songs", JSON.stringify(response));

			const tempSelectSong = _.filter(response, function (s) {
				return s.lyrics.slides.length > 0;
			});

			const songsSelect = tempSelectSong.map(song => ({
				value: song.lyrics.slides,
				label: song.name
			}));
			self.setState({ songsSelect: songsSelect });
		});
	}

	saveSermonNotes = () => {
		const self = this;
		if (self.state.slidesHistory.length > 0) {
			PresentationsService.SaveNotes(self.state.presentationId, self.state.slidesHistory).then(function(response) {
				swal({
                    title: "Saved",
                    text: "The sermon notes have been saved.",
                    icon: "success",
                })
			});
		}
	}

	saveLiveContent = (slide) => {
		const self = this;

		socket.emit('content main', { room: self.state.presentationId, content: slide });

		if (slide) {
			PresentationsService.SaveLiveContent(self.state.presentationId, slide).then(function(response) {
				
			});
		}

	}
	////////////////////////////////////////////////////////////////////////////////////////////////////

	render() {
		const { fontSize, tabOption, showSlides, currentPresentation, themeSelect, selectedTheme, animationSelect, backgroundImage, selectedAnimation, booksSelect, chaptersSelect, selectedBookValue, selectedBookChapterValue, selectedBookVerses, slidesHistory, editorState, songsSelect, openFontSize, anchorElFontSize, openTheme, anchorElTheme, anchorElColorPicker, openColorPicker, anchorElAnimation, openAnimation, updateNoteIndex, anchorElBackgroundImage, openBackgroundImage, textAlign, anchorElTextAlignment, openTextAlignment, textAlignmentSelect } = this.state;
		var currentPresentationSlides = [];

		let headerTitle = "";
		let headerDate = "";
		if (currentPresentation.slides) {
			currentPresentationSlides = currentPresentation.slides;
			headerTitle = currentPresentationSlides[0].details.title;
			headerDate = currentPresentationSlides[0].details.date;
		}

		return (
			<div id="controller-page">
				<Grid container spacing={24}>
					<Grid item xs={8} className="logo-div">
						<img src="/assets/images/Logo-Black.svg" alt=""/>
					</Grid>
					<Grid item xs={4} className="header-title-div">
						<Typography variant="title" className="">{headerTitle}</Typography>
						<Typography variant="subheading" className="">{headerDate}</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={24}>
					<Grid item xs={12} sm={12} md={6} className="slides-div">
						<AppBar position="static">
							<Tabs value={tabOption} onChange={this.handleTabChange}>
								<Tab label="Worship Slides" />
								<Tab label="Sermon Notes" />
							</Tabs>
						</AppBar>
						{tabOption === 0 &&
							<List className="slides-list" id="slides-list" subheader={<li />}>
								{currentPresentationSlides.map((slide, index) => {
									if (slide.type === "intro") {
										return <ListItem button key={index} id={"slide-" + index} className="item" onClick={() => this.changeSlide(slide, index, "outgoing")}>
											<ListItemIcon>
												<TitleIcon className="slides-list-icon" />
											</ListItemIcon>
											<ListItemText
												primary={slide.details.title}
												secondary={
													<React.Fragment>
														<Typography className="verse" dangerouslySetInnerHTML={{ __html: slide.details.date }}></Typography>
														<Typography className="verse" dangerouslySetInnerHTML={{ __html: slide.details.message }}></Typography>
													</React.Fragment>
												}
											/>
										</ListItem>
									}

									if (slide.type === "title") {
										return <ListItem button key={index} id={"slide-" + index} className="item itemTitle" onClick={() => this.changeSlide(slide, index, "outgoing")}>
											<ListItemIcon>
												<MusicNoteIcon className="slides-list-icon" />
											</ListItemIcon>
											<ListItemText
												primary={
													<React.Fragment>
														<Typography className="verse" dangerouslySetInnerHTML={{ __html: slide.details }}></Typography>
													</React.Fragment>
												}
											/>
										</ListItem>
									}

									if (slide.type === "lyrics") {
										return <ListItem button key={index} id={"slide-" + index} className="item" onClick={() => this.changeSlide(slide, index, "outgoing")}>
											<ListItemIcon>
												<QueueMusicIcon className="slides-list-icon" />
											</ListItemIcon>
											<ListItemText
												primary={
													<React.Fragment>
														<Typography className="verse" dangerouslySetInnerHTML={{ __html: slide.details }}></Typography>
													</React.Fragment>
												}
											/>
										</ListItem>
									}

								})}
							</List>
						}
						{tabOption === 1 &&
							<List id="history-list" className="slides-list" subheader={<li />}>
								<ListItem className={slidesHistory.length > 0 ? 'show actions' : 'hide'}>
									<Grid item xs={8}><Typography variant="subtitle1" className="section-title">Settings</Typography></Grid>
									<Grid item xs={4} className="button-div">
										<Button size="small" color="default" className="delete-all-button" onClick={() => this.saveSermonNotes()}><SaveIcon className="button-icon" /> Save</Button>
										<Button size="small" color="default" className="delete-all-button" onClick={() => this.deleteHistory("all")}><DeleteIcon className="button-icon" /> Delete All</Button>
									</Grid>
								</ListItem>
								{slidesHistory.map((slide, index) => {
									if (slide.type === "verse") {
										return <ListItem button key={index} id={"history-" + index} className="item" onClick={() => this.insertHistory(slide, index, "insert", "outgoing")}>
											<ListItemIcon>
												<LibraryBooksIcon className="slides-list-icon" />
											</ListItemIcon>
											<ListItemText
												primary={slide.content.sub}
												secondary={
													<React.Fragment>
														<Typography className="verse" dangerouslySetInnerHTML={{ __html: slide.content.main }}></Typography>
													</React.Fragment>
												}
											/>
											<ListItemSecondaryAction>
												<IconButton edge="end" aria-label="update" onClick={() => this.insertHistory(slide, index, "update", "outgoing")}>
													<FileCopyIcon className="slides-list-icon" />
												</IconButton>
												<IconButton edge="end" aria-label="delete" onClick={() => this.deleteHistory(index)}>
													<DeleteIcon className="slides-list-icon" />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									}

									if (slide.type === "custom") {
										return <ListItem button key={index} id={"history-" + index} className="item" onClick={() => this.insertHistory(slide, index, "insert", "outgoing")}>
											<ListItemIcon>
												<CodeIcon className="slides-list-icon" />
											</ListItemIcon>
											<ListItemText
												primary={
													<React.Fragment>
														<Typography className="verse" dangerouslySetInnerHTML={{ __html: slide.content.main }}></Typography>
													</React.Fragment>
												}
											/>
											<ListItemSecondaryAction>
												<IconButton edge="end" aria-label="update" onClick={() => this.insertHistory(slide, index, "update", "outgoing")}>
													<FileCopyIcon className="slides-list-icon" />
												</IconButton>
												<IconButton edge="end" aria-label="delete" onClick={() => this.deleteHistory(index)}>
													<DeleteIcon className="slides-list-icon" />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									}

									if (slide.type === "image") {
										return <ListItem button key={index} id={"history-" + index} className="item" onClick={() => this.insertHistory(slide, index, "insert", "outgoing")}>
											<ListItemIcon>
												<CameraAltIcon className="slides-list-icon" />
											</ListItemIcon>
											<ListItemText
												primary={
													<React.Fragment>
														<Typography className="verse" dangerouslySetInnerHTML={{ __html: slide.content.main }}></Typography>
													</React.Fragment>
												}
											/>
											<ListItemSecondaryAction>
												<IconButton edge="end" aria-label="update" onClick={() => this.insertHistory(slide, index, "update", "outgoing")}>
													<FileCopyIcon className="slides-list-icon" />
												</IconButton>
												<IconButton edge="end" aria-label="delete" onClick={() => this.deleteHistory(index)}>
													<DeleteIcon className="slides-list-icon" />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									}

									if (slide.type === "video") {
										return <ListItem button key={index} id={"history-" + index} className="item" onClick={() => this.insertHistory(slide, index, "insert", "outgoing")}>
											<ListItemIcon>
												<VideoCamIcon className="slides-list-icon" />
											</ListItemIcon>
											<ListItemText
												primary={
													<React.Fragment>
														<Typography className="verse" dangerouslySetInnerHTML={{ __html: slide.content.main }}></Typography>
													</React.Fragment>
												}
											/>
											<ListItemSecondaryAction>
												<IconButton edge="end" aria-label="update" onClick={() => this.insertHistory(slide, index, "update", "outgoing")}>
													<FileCopyIcon className="slides-list-icon" />
												</IconButton>
												<IconButton edge="end" aria-label="delete" onClick={() => this.deleteHistory(index)}>
													<DeleteIcon className="slides-list-icon" />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									}

								})}
							</List>
						}
						<Grid container spacing={16} className="song-search-div">
							<Grid item xs={12} className="header-div">
								<Typography variant="subtitle1" className="section-title">Song Search</Typography>
								<Button size="small" onClick={() => this.getSongs()}><RefreshIcon className="button-icon" /> Refresh</Button>
							</Grid>
							<Grid item xs={8}>
								<div id="songs-select">
									<Select
										options={songsSelect}
										onChange={this.handleSongSearch}
										className="input-autocomplete"
									/>
								</div>
							</Grid>
							<Grid item xs={4} className="button-insert">
								<Button size="small" onClick={() => this.insertSong("outgoing")}><MusicNoteIcon className="button-icon" /> Insert</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<Grid container spacing={16} className="settings-div">
							<Grid item xs={12}><Typography variant="subtitle1" className="section-title">Settings</Typography></Grid>
							<Grid item xs={6} sm={6} md={3} className="border-right">
								<div className="toggle-display-div">
									<Button size="small" onClick={() => this.displaySlides(showSlides)}><ToggleOffIcon className="button-icon" />  Toggle Display</Button>
								</div>
							</Grid>
							<Grid item xs={6} sm={6} md={3} className="border-right">
								<div className="theme-select-div">
									<Button size="small" onClick={() => this.handleThemeToggle(showSlides)}><FormatPaintIcon className="button-icon" />  Change Theme</Button>
									<Popper
										id="theme"
										open={openTheme}
										anchorEl={anchorElTheme}
										transition
										placement="bottom"
										disablePortal={true}
										modifiers={{
											flip: {
												enabled: false,
											},
											preventOverflow: {
												enabled: true,
												boundariesElement: 'scrollParent',
											},
										}}
									>
										{({ TransitionProps }) => (
											<Fade {...TransitionProps} timeout={350}>
												<div id="theme-select">
													<Select
														options={themeSelect}
														onChange={this.handleThemeChange}
														value={selectedTheme}
														className="input-autocomplete"
													/>
												</div>
											</Fade>
										)}
									</Popper>
								</div>
							</Grid>
							<Grid item xs={6} sm={6} md={3} className="border-right">
								<div className="font-size-div">
									<Button size="small" onClick={() => this.handleFontSizeToggle(showSlides)}><FormatSizeIcon className="button-icon" />  Font Size</Button>
									<Popper
										id="font-size"
										open={openFontSize}
										anchorEl={anchorElFontSize}
										transition
										placement="bottom"
										disablePortal={true}
										modifiers={{
											flip: {
												enabled: false,
											},
											preventOverflow: {
												enabled: true,
												boundariesElement: 'scrollParent',
											},
										}}
									>
										{({ TransitionProps }) => (
											<Fade {...TransitionProps} timeout={350}>
												<div className="font-size-input-div">
													<TextField
														id="outlined-name"
														label="Font Size Base"
														variant="outlined"
														value={fontSize}
														margin="normal"
														// variant="filled"
														className="input-field-font-size"
														type="number"
														onChange={this.handleFontSizeChange}
													/>
												</div>
											</Fade>
										)}
									</Popper>
								</div>
							</Grid>
							<Grid item xs={6} sm={6} md={3} className="border-right">
								<div className="color-picker-div">
									<Button size="small" onClick={() => this.handleColorChangeToggle(showSlides)}><ColorLensIcon className="button-icon" />  Font Color</Button>
									<Popper
										id="color-picker"
										open={openColorPicker}
										anchorEl={anchorElColorPicker}
										transition
										placement="bottom"
										disablePortal={true}
										modifiers={{
											flip: {
												enabled: false,
											},
											preventOverflow: {
												enabled: true,
												boundariesElement: 'scrollParent',
											},
										}}
									>
										{({ TransitionProps }) => (
											<Fade {...TransitionProps} timeout={350}>
												<SketchPicker onChangeComplete={this.handleColorChangeComplete} />
											</Fade>
										)}
									</Popper>
								</div>
							</Grid>
							<Grid item xs={6} sm={6} md={3} className="border-right">
								<div className="animation-select-div">
									<Button size="small" onClick={() => this.handleAnimationToggle(showSlides)}><FastForwardIcon className="button-icon" />  Change Animation</Button>
									<Popper
										id="theme"
										open={openAnimation}
										anchorEl={anchorElAnimation}
										transition
										placement="bottom"
										disablePortal={true}
										modifiers={{
											flip: {
												enabled: false,
											},
											preventOverflow: {
												enabled: true,
												boundariesElement: 'scrollParent',
											},
										}}
									>
										{({ TransitionProps }) => (
											<Fade {...TransitionProps} timeout={350}>
												<div id="animation-select">
													<Select
														options={animationSelect}
														onChange={this.handleAnimationChange}
														value={selectedAnimation}
														className="input-autocomplete"
													/>
												</div>
											</Fade>
										)}
									</Popper>
								</div>
							</Grid>
							<Grid item xs={6} sm={6} md={3} className="border-right">
								<div className="background-image-div">
									<Button size="small" onClick={() => this.handleBackgroundImageToggle(showSlides)}><CollectionsIcon className="button-icon" />  Background Image</Button>
									<Popper
										id="background-image"
										open={openBackgroundImage}
										anchorEl={anchorElBackgroundImage}
										transition
										placement="bottom"
										disablePortal={true}
										modifiers={{
											flip: {
												enabled: false,
											},
											preventOverflow: {
												enabled: true,
												boundariesElement: 'scrollParent',
											},
										}}
									>
										{({ TransitionProps }) => (
											<Fade {...TransitionProps} timeout={350}>
												<div className="background-image-input-div">
													<TextField
														id="outlined-name"
														label="Background Image"
														variant="outlined"
														value={backgroundImage}
														margin="normal"
														// variant="filled"
														className="input-field-background-image"
														type="text"
														onChange={this.handleBackgroundImageInput('backgroundImage')}
														onBlur={this.handleBackgroundImageChange(backgroundImage)}
													/>
												</div>
											</Fade>
										)}
									</Popper>
								</div>
							</Grid>
							<Grid item xs={6} sm={6} md={3} className="border-right">
								<div className="text-alignment-div">
									<Button size="small" onClick={() => this.handleTextAlignmentToggle(showSlides)}><FormatAlignLeftIcon className="button-icon" />  Text Alignment</Button>
									<Popper
										id="text-alignment"
										open={openTextAlignment}
										anchorEl={anchorElTextAlignment}
										transition
										placement="bottom"
										disablePortal={true}
										modifiers={{
											flip: {
												enabled: false,
											},
											preventOverflow: {
												enabled: true,
												boundariesElement: 'scrollParent',
											},
										}}
									>
										{({ TransitionProps }) => (
											<Fade {...TransitionProps} timeout={350}>
												<div id="text-alignment-select">
													<Select
														options={textAlignmentSelect}
														onChange={this.handleTextAlignmentChange}
														value={textAlign}
														className="input-autocomplete"
													/>
												</div>
											</Fade>
										)}
									</Popper>
								</div>
							</Grid>
							<Grid item xs={6} sm={6} md={3} className="border-right">
								<div className="text-alignment-div">
									<Button size="small" onClick={() => this.handleRefreshRequest(showSlides)}><RefreshIcon className="button-icon" />  Refresh</Button>
								</div>
							</Grid>
						</Grid>
						<Grid container spacing={16} className="verse-search-div">
							<Grid item xs={12}><Typography variant="subtitle1" className="section-title">Verse Search</Typography></Grid>
							<Grid item xs={6} sm={4}>
								<div id="books-select">
									<Select
										options={booksSelect}
										onChange={this.handleBookSelection}
										value={selectedBookValue}
										className="input-autocomplete"
									/>
								</div>
							</Grid>
							<Grid item xs={3} sm={3}>
								<div id="chapters-select">
									<Select
										options={chaptersSelect}
										onChange={this.handleChapterSelection}
										value={selectedBookChapterValue}
										className="input-autocomplete"
									/>
								</div>
							</Grid>
							<Grid item xs={3} sm={3}>
								<TextField
									id="outlined-name"
									label="verse"
									variant="outlined"
									value={selectedBookVerses}
									margin="normal"
									// variant="filled"
									className="input-field-verse"
									onChange={this.handleVerseSelection}
								/>
							</Grid>

							<Grid item xs={12} sm={2} className="button-get">
								<Button size="small" onClick={() => this.getVerse()}><SearchIcon className="button-icon" /> Get</Button>
							</Grid>
						</Grid>
						<Grid container spacing={16} className="text-editor-div">
							<Grid item xs={12}><Typography variant="subtitle1" className="section-title">Content Editor</Typography></Grid>
							<Grid item xs={12}>
								<Editor
									editorState={editorState}
									toolbarClassName="toolbarClassName"
									wrapperClassName="wrapperClassName"
									editorClassName="editorClassName"
									onEditorStateChange={this.onEditorStateChange}
								/>
								<div id="tempVerseDiv"></div>
							</Grid>
						</Grid>
						<Grid container spacing={16} className="buttons-div">
							{updateNoteIndex !== "" &&
								<Grid item xs={12}>
									<Button size="small" color="default" onClick={() => this.updateNote()}><LibraryBooksIcon className="button-icon" /> Update Note</Button>
								</Grid>
							}
							<Grid item xs={3}>
								<Button size="small" color="default" onClick={() => this.insertVerse("insert", "outgoing")}><LibraryBooksIcon className="button-icon" /> Insert Verse</Button>
							</Grid>
							<Grid item xs={3}>
								<Button size="small" color="default" onClick={() => this.insertCustom("insert")}><CodeIcon className="button-icon" /> Insert Custom</Button>
							</Grid>
							<Grid item xs={3}>
								<Button size="small" color="default" onClick={() => this.insertImage("insert")}><CameraAltIcon className="button-icon" /> Insert Image</Button>
							</Grid>
							<Grid item xs={3}>
								<Button size="small" color="default" onClick={() => this.insertVideo("insert")}><VideoCamIcon className="button-icon" /> Insert Video</Button>
							</Grid>
							
							<Grid item xs={3}>
								<Button size="small" color="default" onClick={() => this.insertVerse("add", "outgoing")}><LibraryBooksIcon className="button-icon" /> Add Verse</Button>
							</Grid>
							<Grid item xs={3}>
								<Button size="small" color="default" onClick={() => this.insertCustom("add")}><CodeIcon className="button-icon" /> Add Custom</Button>
							</Grid>
							<Grid item xs={3}>
								<Button size="small" color="default" onClick={() => this.insertImage("add")}><CameraAltIcon className="button-icon" /> Add Image</Button>
							</Grid>
							<Grid item xs={3}>
								<Button size="small" color="default" onClick={() => this.insertVideo("add")}><VideoCamIcon className="button-icon" /> Add Video</Button>
							</Grid>
						</Grid>
						<Grid container spacing={16} className="resources-div">
							<Grid item xs={12}><Typography variant="subtitle1" className="section-title">Resources</Typography></Grid>
							<Grid item xs={12}>
								<List id="resources-list" className="resources-list" subheader={<li />}>
									<ListItem button className="item" onClick={() => this.viewResource("http://www.supalogo.com")}>
										<ListItemText
											primary={
												<React.Fragment>
													<Typography className="verse">Logo Creating</Typography>
												</React.Fragment>
											}
										/>
									</ListItem>
									<ListItem button className="item" onClick={() => this.viewResource("http://www.postimg.cc")}>
										<ListItemText
											primary={
												<React.Fragment>
													<Typography className="verse">Image Hosting</Typography>
												</React.Fragment>
											}
										/>
									</ListItem>
									<ListItem button className="item" onClick={() => this.viewResource("http://www.generateit.net/reflection")}>
										<ListItemText
											primary={
												<React.Fragment>
													<Typography className="verse">Image Generator</Typography>
												</React.Fragment>
											}
										/>
									</ListItem>
								</List>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default ControllerPage;