import { createStore, applyMiddleware } from "redux";
import { createStateSyncMiddleware } from 'redux-state-sync';
import thunk from 'redux-thunk';
import rootReducer from "./reducers";

const config = {
    initiateWithState: true, 
}
const middlewares = [
    createStateSyncMiddleware(config),
];

export default createStore(rootReducer, applyMiddleware(thunk,...middlewares));