import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import { initializeData } from '../../redux/actions';
import './MenuNavigation.css';

class MenuItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeMenu: "",
            menuItems: [{
                name: "Home",
                icon: "MailIcon",
                route: "/",
            }, {
                name: "Assets",
                icon: "MailIcon",
                route: "/assets"
            }, {
                name: "Presentations",
                icon: "MailIcon",
                route: "/presentations"
            }]
        };
    }

    componentDidMount() {
        this.props.initializeData();
        console.log("inital menu Page", this.props);
    }
    componentDidUpdate() {
        console.log("updated menu Page", this.props);
    }

    updateInput = input => {
        this.setState({ input });
    };

    handleAddTodo = () => {
        this.props.addTodo(this.state.input);
        this.setState({ input: "" });
    };

    openPresentationController = () => {
        window.open('/c/75', 'height=670,width=1400,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no');
    }
    //https://stackoverflow.com/questions/33471880/dynamic-tag-name-in-jsx-and-react
    render() {
        const { menuItems } = this.state;
        return (
            <div id="menu-navigation">
                {menuItems.map((item, index) => (
                    <Link to={item.route} key={index}><Button color="inherit">{item.name}</Button></Link>
                ))}
            </div>
        );
    }
}

const mapStateToProps = state => (
    { activeMenu: state.hofcc.activeMenu }
)

const mapDispatchToProps = dispatch => ({
    initializeData: () => dispatch(initializeData()),
});

export default connect("", mapDispatchToProps)(MenuItems);