
import React, { Component } from 'react';
import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import Drawer from '@material-ui/core/Drawer';

import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import FormatPaintIcon from '@material-ui/icons/FormatPaint';
import DeleteIcon from '@material-ui/icons/Delete';
import ToggleOffIcon from '@material-ui/icons/ToggleOff'
import TitleIcon from '@material-ui/icons/Title'
import SaveIcon from '@material-ui/icons/Save'
import RefreshIcon from '@material-ui/icons/Refresh'
import FastForwardIcon from '@material-ui/icons/FastForward'
import MusicNoteIcon from '@material-ui/icons/MusicNote'
import QueueMusicIcon from '@material-ui/icons/QueueMusic'
import ColorLensIcon from '@material-ui/icons/ColorLens'
import FormatSizeIcon from '@material-ui/icons/FormatSize'
import SearchIcon from '@material-ui/icons/Search';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import VideoCamIcon from '@material-ui/icons/Videocam';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CodeIcon from '@material-ui/icons/Code';
// import UpdateIcon from '@material-ui/icons/Update';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import CollectionsIcon from '@material-ui/icons/Collections';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';

import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BroadcastChannel from 'broadcast-channel';
import Select from 'react-select';
import { SketchPicker } from 'react-color';
import swal from 'sweetalert';
import _ from 'lodash';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import './RemotePage.css';
import { ListItemText } from '@material-ui/core';

import PresentationsService from '../../../services/presentations.js';
import BibleService from '../../../services/bible.js';
import SongsService from '../../../services/songs';

import { LIVE_PRESENTATION_ENDPOINT } from '../../../constants/enviroment';

import io from 'socket.io-client';
const socket = io(LIVE_PRESENTATION_ENDPOINT);

const options = {
	type: 'localstorage', // (optional) enforce a type, oneOf['native', 'idb', 'localstorage', 'node']
};

const change_theme = new BroadcastChannel('change-theme', options);
const change_text_size = new BroadcastChannel('change-text-size', options);
const change_text_color = new BroadcastChannel('change-text-color', options);
const display_slides = new BroadcastChannel('display-slides', options);
const channel_slide_change = new BroadcastChannel('slide-change', options);
const insert_content = new BroadcastChannel('insert-content', options);
const change_animation = new BroadcastChannel('change-animation', options);
const change_background_image = new BroadcastChannel('change-background-image', options);
const change_text_alignment = new BroadcastChannel('change-text-alignment', options);

class RemotePage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			alphabetArray: ['#','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
			tabOption: 0,
			showSlides: true,
			presentationId: this.props.match.params.presentationid,
			currentPresentation: {},
			songs: [],
			books: [],
			booksSelect: [],
			chaptersSelect: [],
			songsSelect: [],

			showVerseInsert: false,
			selectedSongSlides: [],
			selectedBook: "",
			selectedBookValue: {},
			selectedBookChapter: 0,
			selectedBookChapterValue: {},
			selectedBookVerses: "",
			selectPassageVerses: [],
			selectedBookVerseReference: 0,
			toggleBookSelection: false,
			toogleChapterSelection: false,

			slidesHistory: [],

			editorState: EditorState.createEmpty(),

			fontSize: 15,
			textAlign: "",
			openFontSize: null,
			anchorElFontSize: null,
			anchorElColorPicker: null,
			openColorPicker: false,
			anchorElTheme: null,
			openTheme: false,
			anchorElAnimation: null,
			openAnimation: false,
			anchorElBackgroundImage: null,
			openBackgroundImage: false,
			anchorElTextAlignment: null,
			openTextAlignment: false,
			updateNoteIndex: "",
			showWorship: true
		}
	}

	componentDidMount() {
		document.title = "HOFCC | Remote";
		document.getElementsByTagName("header")[0].style.display = "none";

		this.getPresentation();

		socket.emit('joined room', this.state.presentationId);

		const self = this;
		BibleService.GetBooks().then(function (response) {
			const booksSelect = response.map(book => ({
				value: book.name,
				label: book.name
			}));
			self.setState({ books: response, booksSelect: booksSelect });
		});

		this.getSongs();

		if (localStorage.getItem("presentationID") !== this.state.presentationId) {
			localStorage.setItem("presentationID", this.state.presentationId);
			localStorage.setItem("history", "[]");
		}

		socket.on('content update remote', (content) => {
			const action = content.action;
			const slide = content.content;

			if (action == "changeSlide") {
				this.changeSlide(slide, content.index, "incoming");
			}
			else if (action == "insertVerse") {

				let book = "";
				let chapter = "";
				let verse = "";
				let bookObject = {};
				let chapterArray = [];
				let chapterObject = {};
				
				const reference = slide.content.sub;
				const referenceArray = reference.split(" ");

				book = referenceArray[0].length === 1 ? referenceArray[0] + " " + referenceArray[1] : referenceArray[0];
				bookObject = { value: book, label: book };

				this.handleBookSelection(bookObject);

				chapter = referenceArray[0].length === 1 ? referenceArray[2] : referenceArray[1];
				chapterArray = chapter.split(":")[0];
				chapterObject = { value: chapterArray, label: chapterArray };

				verse = reference.split(":")[1];

				this.setState({
					selectedBook: book,
					selectedBookValue: bookObject,
					selectedBookChapter: chapterArray,
					selectedBookChapterValue: chapterObject,
					selectedBookVerses: verse,
				});

				this.setSelectedVerse(slide);
				this.handleVerseSelection(verse, slide.content.main, "incoming")
				
			}
			else if (action == "insertHistory") {
				this.insertHistory(slide, content.index, content.insertType, "incoming");
			}
			else if (action == "insertSong") {
				const song = JSON.parse(slide);	
				this.setState({
					currentPresentation: {
						...this.state.currentPresentation,
						slides: [
							...this.state.currentPresentation.slides, ...song
						]
					}
				})
			}
			else if (action == "updateNote") {
				this.updateNote(slide, content.index);
			}
		});
	}

	//PASSAGE SELECTION
	clearPassage = () => {
		this.setState({ toggleBookSelection: false, toggleChapterSelection: false, selectedBook: "", selectedBookValue: {}, chaptersSelect: [], selectedBookChapter: 0, selectedBookChapterValue: {}, selectPassageVerses: [], selectedBookVerseReference: 0 });
	}

	showBookSelection = () => {
		this.setState({ toggleBookSelection: true, toggleChapterSelection: false, selectedBook: "", selectedBookValue: {}, chaptersSelect: [], selectedBookChapter: 0, selectedBookChapterValue: {}, selectPassageVerses: [], selectedBookVerseReference: 0 });
	}

	handleBookSelection = (pBook) => {
		var chaptersSelect = [];
		for (var i = 1; i <= pBook.chapters; i++) {
			chaptersSelect.push({ value: i, label: i });
		}
		this.setState({ toggleBookSelection: false, toggleChapterSelection: true, selectedBook: pBook.name, selectedBookValue: pBook, chaptersSelect: chaptersSelect });
	}

	showChapterSelection = () => {
		this.setState({ toggleBookSelection: false, toggleChapterSelection: true, selectedBookChapter: 0, selectedBookChapterValue: {}, selectPassageVerses: [], selectedBookVerseReference: 0 });
	}

	handleChapterSelection = (pChapter) => {
		const { selectedBook } = this.state;
		this.setState({ toggleBookSelection: false, toggleChapterSelection: false, selectedBookChapter: pChapter.value, selectedBookChapterValue: pChapter, selectPassageVerses: [] });
		const self = this;
		BibleService.GetChapter(selectedBook, pChapter.value).then(function (response) {
			if (response.verses.length > 0) {
				self.setState({ selectPassageVerses: response.verses })
			}
		})
	}

	handleVerseSelection = (pReference, pVerse, pDirection) => {
		let verse = pVerse;
		if (pDirection == "incoming") {
			verse = pVerse.replace(/\*/gi, "").replace(/\u2018/gi, "'").replace(/\u2019/gi, "'").replace(/\u201c/gi, '"').replace(/\u201d/gi, '"').replace(/\u2014/gi, '--');

		}
		else {
			verse = pVerse.text.replace(/\*/gi, "").replace(/\u2018/gi, "'").replace(/\u2019/gi, "'").replace(/\u201c/gi, '"').replace(/\u201d/gi, '"').replace(/\u2014/gi, '--');
		}
		const content = {
			type: 'verse',
			content: {
				main: verse,
				sub: this.state.selectedBook + " " + this.state.selectedBookChapter + ":" + pReference
			}
		};

		this.saveSlideHistory(content);
		this.setState({ selectedBookVerseReference: pReference, selectedBookVerse: verse });

		if (pDirection == "outgoing") {
			socket.emit('content external', { action: "insertVerse", content: content, room: this.state.presentationId });
		}
		else {
			//this.clearPassage()
		}

	};

	saveSlideHistory = (slide) => {
		this.setState({ slidesHistory: [...this.state.slidesHistory, slide] })
		localStorage.setItem("history", JSON.stringify([...this.state.slidesHistory, slide]));
	}

	showWorship = () => {
		this.setState({ showWorship: true });
	}

	showSermon = () => {
		this.setState({ showWorship: false });
	}

	toggleDrawer = () => {
		this.setState({ showVerseInsert: !this.state.showVerseInsert })
	}

	onEditorStateChange = (editorState) => {
		this.setState({
			editorState,
		});
	};

	insertSong = (song, pDirection) => {
		this.toggleDrawer();
		const songTitle = { "details": song.label, "order": 0, "type": "title" };
		this.setState({
			currentPresentation: {
				...this.state.currentPresentation,
				slides: [
					...this.state.currentPresentation.slides, ...[songTitle].concat(song.value)
				]
			}
		})

		if (pDirection == "outgoing") {
			socket.emit('content external', { action: "insertSong", content: JSON.stringify(song), room: this.state.presentationId});
		}
	}


	handleTabChange = (event, newValue) => {
		this.setState({ tabOption: newValue })
	}

	handleRefreshRequest = () => {
		this.getPresentation();
	}
	////////////////////////////////////////////////////////////////////////////////////////////////////

	displaySlides = (value) => {
		this.setState({ showSlides: !value, tabOption: value ? 1 : 0 });
		display_slides.postMessage(!value);
	}

	changeSlide = (slide, index, pDirection) => {
		if (document.getElementById("slides-list")) {
			for (var i = 0; i < document.getElementById("slides-list").getElementsByClassName("item").length; i++) {
				document.getElementById("slides-list").getElementsByClassName("item")[i].classList.remove("active");
			}
			document.getElementById("slide-" + index).classList.add("active");

			if (pDirection == "outgoing") {
				socket.emit('content external', { action: "changeSlide", content: slide, room: this.state.presentationId, index: index });
			}
		}
	}

	insertHistory = (slide, index, action, pDirection) => {
		if (document.getElementById("history-list")) {
			for (var i = 0; i < document.getElementById("history-list").getElementsByClassName("item").length; i++) {
				document.getElementById("history-list").getElementsByClassName("item")[i].classList.remove("active");
			}

			document.getElementById("history-" + index).classList.add("active");
		}

		if (slide.type == "verse") {
			this.setSelectedVerse(slide);
		}
		
		if (pDirection == "outgoing") {
			socket.emit('content external', { action: "insertHistory", content: slide, room: this.state.presentationId, index: index,  insertType: action});
		}
	}

	setSelectedVerse = (pSlide) => {
		const reference = pSlide.content.sub
		const referenceArray = reference.split(" ");
		let book = referenceArray[0].length === 1 ? referenceArray[0] + " " + referenceArray[1] : referenceArray[0];
		let bookObject = _.find(this.state.books, { 'name': book });
		var chaptersSelect = [];
		for (var i = 1; i <= bookObject.chapters; i++) {
			chaptersSelect.push({ value: i, label: i });
		}
		let chapter = referenceArray[0].length === 1 ? referenceArray[2] : referenceArray[1];
		let chapterArray = chapter.split(":")[0];

		let verse = reference.split(":")[1];

		if (book != this.state.selectedBook && chapterArray != this.state.selectedBookChapter) {
			const self = this;
			this.setState({ toggleBookSelection: false, toggleChapterSelection: false, selectedBook: bookObject.name, selectedBookValue: bookObject, chaptersSelect: chaptersSelect, selectedBookChapter: parseInt(chapterArray), selectedBookChapterValue: {value: parseInt(chapterArray), label: parseInt(chapterArray)}, selectPassageVerses: [] });
			BibleService.GetChapter(bookObject.name, parseInt(chapterArray)).then(function (response) {
				if (response.verses.length > 0) {
					self.setState({ selectPassageVerses: response.verses, selectedBookVerseReference: verse })
				}
			})
		}
	}

	updateNote = (content, pIndex) => {
		const tempHistory = this.state.slidesHistory;
		const updateNoteIndex = parseInt(pIndex)

		tempHistory[updateNoteIndex].content = content;
		this.setState({ slidesHistory: tempHistory })
	}

	////////////////////////////////////////////////////////////////////////////////////////////////////

	getEditorContent = (type) => {
		if (type === "text") {
			return convertToRaw(this.state.editorState.getCurrentContent()).blocks[0].text;
		}
		return draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
	}

	////////////////////////////////////////////////////////////////////////////////////////////////////

	loadHistory = () => {
		if (this.state.currentPresentation.notes) {
			this.setState({ slidesHistory: this.state.currentPresentation.notes });
			localStorage.setItem("history", JSON.stringify(this.state.currentPresentation.notes));
		}
		else {
			if (localStorage.getItem("history") !== "undefined") {
				if (localStorage.getItem("history") !== "" && localStorage.getItem("history") !== null) {
					const history = JSON.parse(localStorage.getItem("history"));
					if (history.length > 0) {
						this.setState({ slidesHistory: history });
					}
				}
			}
		}
	}

	deleteHistory = (pIndex) => {
		const self = this;
		swal({
			title: "Are you sure?",
			text: "The item(s) will be deleted.",
			icon: "error",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {

					if (localStorage.getItem("history") !== "undefined") {
						if (pIndex === "all") {
							self.setState({ slidesHistory: [] })
							localStorage.setItem("history", []);
						}
						else {
							const updatedHistory = _.filter(self.state.slidesHistory, function (o, index) {
								return index !== pIndex;
							});

							self.setState({ slidesHistory: updatedHistory });
							localStorage.setItem("history", JSON.stringify(updatedHistory));
						}
					}

					return true;
				}
				else {
					return false;
				}
			});

	}


	getPresentation = async () => {
		const self = this;

		PresentationsService.RetrieveAll().then(function (response) {
			let currentPresentation;
			if (self.state.presentationId != 0) {
				currentPresentation = _.find(response, { 'presentationID': self.state.presentationId });
			}
			else {
				currentPresentation = response[0];
			}
			localStorage.setItem("presentation", JSON.stringify(currentPresentation));
			self.setState({
				currentPresentation: currentPresentation
			});

			self.loadHistory();
		});
	}

	getVerse = (pAction) => {
		const { selectedBook, selectedBookChapter, selectedBookVerses } = this.state;

		let selectedVerses = selectedBookVerses;
		if (pAction === "next") {
			if (selectedVerses.toString().indexOf('-') > -1) {
				selectedVerses = selectedVerses.toString().split("-")[1];
			}

			selectedVerses++;
			this.setState({ selectedBookVerses: selectedVerses });
		}
		const self = this;

		BibleService.GetPassage(selectedBook, selectedBookChapter, selectedVerses).then(function (response) {
			if (response.length > 0) {
				var verse = "";
				for (var i = 0; i < response.length; i++) {
					verse += response[i].text + " ";
				}
				verse = verse.replace(/\*/gi, "").replace(/\u2018/gi, "'").replace(/\u2019/gi, "'").replace(/\u201c/gi, '"').replace(/\u201d/gi, '"').replace(/\u2014/gi, '--');
				document.getElementById("tempVerseDiv").innerHTML = verse;
				const contentBlock = htmlToDraft(document.getElementById("tempVerseDiv").textContent);
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
				const editorState = EditorState.createWithContent(contentState);
				self.setState({
					editorState,
				});
			}
		})
	}

	getSongs = async () => {
		const tempSelectSong = _.filter(this.state.songs, function (s) {
			return s.lyrics.slides.length > 1;
		});

		const songsSelect = tempSelectSong.map(song => ({
			value: song.lyrics.slides,
			label: song.name
		}));
		this.setState({ songsSelect: songsSelect });

		const self = this;
		SongsService.RetrieveAll().then(function (response) {
			self.setState({ songs: response });
			localStorage.setItem("songs", JSON.stringify(response));

			const tempSelectSong = _.filter(response, function (s) {
				return s.lyrics.slides.length > 0;
			});

			const songsSelect = tempSelectSong.map(song => ({
				value: song.lyrics.slides,
				label: song.name
			}));
			self.setState({ songsSelect: songsSelect });
		});
	}

	saveSermonNotes = () => {
		socket.emit('content external', { action: "saveSermonNotes", content: {}, room: this.state.presentationId });
	}

	////////////////////////////////////////////////////////////////////////////////////////////////////

	render() {
		const { fontSize, tabOption, showSlides, currentPresentation, themeSelect, selectedTheme, animationSelect, backgroundImage, selectedAnimation, booksSelect, chaptersSelect, selectedBookValue, selectedBookChapterValue, selectedBookVerses, slidesHistory, editorState, songsSelect, openFontSize, anchorElFontSize, openTheme, anchorElTheme, anchorElColorPicker, openColorPicker, anchorElAnimation, openAnimation, updateNoteIndex, anchorElBackgroundImage, openBackgroundImage, textAlign, anchorElTextAlignment, openTextAlignment, textAlignmentSelect, books, selectedBook, selectedBookChapter, toggleBookSelection, toggleChapterSelection, selectPassageVerses, selectedBookVerseReference, showVerseInsert, showWorship, alphabetArray } = this.state;
		var currentPresentationSlides = [];

		let headerTitle = "";
		let headerDate = "";
		if (currentPresentation.slides) {
			currentPresentationSlides = currentPresentation.slides;
			headerTitle = currentPresentationSlides[0].details.title;
			headerDate = currentPresentationSlides[0].details.date;
		}

		return (
			<div id="remote-page">

				<CssBaseline />
				<AppBar position="fixed" className="appBar">
					<Toolbar className="appBar-Tools">
						<IconButton edge="start" color="inherit" onClick={() => this.showWorship()}>
							Worship
						</IconButton>
						<Fab color="secondary" aria-label="add" className="fabButton" onClick={() => this.toggleDrawer()}>
							<ExpandLessIcon />
						</Fab>
						<IconButton edge="end" color="inherit" onClick={() => this.showSermon()}>
							Sermon
						</IconButton>
						<IconButton edge="end" color="inherit" onClick={() => this.saveSermonNotes()}>
							<SaveIcon />
						</IconButton>
						<IconButton edge="end" color="inherit" onClick={() => this.handleRefreshRequest(showSlides)}>
							<RefreshIcon />
						</IconButton>
					</Toolbar>
				</AppBar>

				<Drawer anchor="bottom" open={this.state.showVerseInsert}>


				{showWorship ? ( 
					<Grid container spacing={16} className="verse-search-div-remote">
						<AppBar position="fixed" className="appBar-verse">
							<Toolbar>
								<Grid container spacing={16} className="verse-controls">
									<Grid item xs={4} style={{ textAlign: "left" }}>
										<Button size="small" className="select-option">
											Songs List
										</Button>
									</Grid>
									<Grid item xs={5} style={{ textAlign: "left" }}>
										<Button size="small" onClick={() => this.getSongs()}>
											<RefreshIcon />
										</Button>
									</Grid>
								</Grid>
								<Fab color="secondary" aria-label="add" className="fabButton-verse" onClick={() => this.toggleDrawer()}>
									<ExpandMoreIcon />
								</Fab>

							</Toolbar>
						</AppBar>

						<Grid className="passage-select">
							<Grid container spacing={16} className="verse-select">
								{/* {songsSelect.map((song, index) => {
									return (
										<Grid item xs={12}>
											<Button size="small">
												{song.label}
											</Button>
										</Grid>
									)
								})} */}

								{alphabetArray.map(sectionId => (
									<Grid container spacing={16}>
										
											<Grid item xs={12} className="songsList-divider">
												<Button size="small">
													{`${sectionId}`}
												</Button>
											</Grid>											
											{ songsSelect
												.filter(function (song) {
													if (sectionId === '#') {
														return Number.isInteger(parseInt(song.label.charAt(0)));
													}
													return song.label.charAt(0) === sectionId;
												})
												.map(item => (
													<Grid item xs={12}>
														<Button size="small" onClick={() => this.insertSong(item, "outgoing")}>
															{item.label}
														</Button>
													</Grid>
												))
											}

									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
				) 
				: 
				(
					<Grid container spacing={16} className="verse-search-div-remote">
						<AppBar position="fixed" className="appBar-verse">
							<Toolbar>
								<Grid container spacing={16} className="verse-controls">
									<Grid item xs={4} style={{ textAlign: "left" }}>
										<Button size="small" className="select-option" onClick={() => this.showBookSelection()}>
											{selectedBook === "" ?
												(<Typography variant="inherit">Book</Typography>)
												:
												(<Typography variant="inherit"><span className="selected-option">{selectedBookValue.abbreviation}</span></Typography>)
											}
										</Button>
									</Grid>
									{selectedBook !== "" &&
										<Grid item xs={3} style={{ textAlign: "left" }}>
											<Button size="small" className="select-option" onClick={() => this.showChapterSelection()}>
												{selectedBookChapter === 0 ?
													(<Typography variant="inherit">Chapter</Typography>)
													:
													(<Typography variant="inherit"><span className="selected-option">{selectedBookChapter}</span></Typography>)
												}
											</Button>
										</Grid>
									}
									{selectedBook !== "" &&
										<Grid item xs={5} style={{ textAlign: "left" }}>
											<Button size="small" onClick={() => this.clearPassage()}>
												<CloseIcon />
											</Button>
										</Grid>
									}
								</Grid>
								<Fab color="secondary" aria-label="add" className="fabButton-verse" onClick={() => this.toggleDrawer()}>
									<ExpandMoreIcon />
								</Fab>

							</Toolbar>
						</AppBar>

						<Grid className="passage-select">

							{selectedBook == "" &&
								<Grid container spacing={16} className="books-select">
									{books.map((book, index) => {
										return (
											<Grid item xs={4} sm={3}>
												<Button size="small" onClick={() => this.handleBookSelection(book)}>{book.name}</Button>
											</Grid>
										)
									})}
								</Grid>
							}

							{toggleChapterSelection &&
								<Grid container spacing={16} className="chapter-select">
									{chaptersSelect.map((chapter, index) => {
										return (
											<Grid item xs={3}>
												<Button size="small" onClick={() => this.handleChapterSelection(chapter)}>{chapter.value}</Button>
											</Grid>
										)
									})}
								</Grid>
							}

							<Grid container spacing={16} className="verse-select">
								{selectPassageVerses.map((verse, index) => {
									return (
										<Grid item xs={12}>
											<Button size="small" onClick={() => this.handleVerseSelection((index + 1), verse, "outgoing")} className={((index + 1) == selectedBookVerseReference ? 'active' : '')}>
												<span className="reference">{index + 1}.</span>
												{verse.text}
											</Button>
										</Grid>
									)
								})}
							</Grid>
						</Grid>
					</Grid>
				)
				}

				</Drawer>

				<Grid container spacing={24} className="header-section">
					<Grid item xs={4} className="logo-div">
						<img src="/assets/images/Logo-Black.svg" alt="" />
					</Grid>
					<Grid item xs={8} className="header-title-div">
						<Typography variant="title" className="">{headerTitle}</Typography>
						<Typography variant="subheading" className="">{headerDate}</Typography>
					</Grid>
				</Grid>

				<Grid container spacing={24} className="body-section">
					{showWorship ? (
						<Grid item xs={12} sm={12} className="worship-slides-div">

							<List className="slides-list slides-list-remote" id="slides-list" subheader={<li />}>
								{currentPresentationSlides.map((slide, index) => {
									if (slide.type === "intro") {
										return <ListItem button key={index} id={"slide-" + index} className="item" onClick={() => this.changeSlide(slide, index, "outgoing")}>
											<ListItemIcon>
												<TitleIcon className="slides-list-icon" />
											</ListItemIcon>
											<ListItemText
												primary={slide.details.title}
												secondary={
													<React.Fragment>
														<Typography className="verse" dangerouslySetInnerHTML={{ __html: slide.details.date }}></Typography>
														<Typography className="verse" dangerouslySetInnerHTML={{ __html: slide.details.message }}></Typography>
													</React.Fragment>
												}
											/>
										</ListItem>
									}

									if (slide.type === "title") {
										return <ListItem button key={index} id={"slide-" + index} className="item itemTitle" onClick={() => this.changeSlide(slide, index, "outgoing")}>
											<ListItemIcon>
												<MusicNoteIcon className="slides-list-icon" />
											</ListItemIcon>
											<ListItemText
												primary={
													<React.Fragment>
														<Typography className="verse" dangerouslySetInnerHTML={{ __html: slide.details }}></Typography>
													</React.Fragment>
												}
											/>
										</ListItem>
									}

									if (slide.type === "lyrics") {
										return <ListItem button key={index} id={"slide-" + index} className="item" onClick={() => this.changeSlide(slide, index, "outgoing")}>
											<ListItemIcon>
												<QueueMusicIcon className="slides-list-icon" />
											</ListItemIcon>
											<ListItemText
												primary={
													<React.Fragment>
														<Typography className="verse" dangerouslySetInnerHTML={{ __html: slide.details }}></Typography>
													</React.Fragment>
												}
											/>
										</ListItem>
									}

								})}
							</List>

						</Grid>
					)
					:
					(
						<Grid item xs={12} sm={12} className="sermon-slides-div">

							<List id="history-list" className="slides-list slides-list-remote" subheader={<li />}>
								{slidesHistory.map((slide, index) => {
									if (slide.type === "verse") {
										return <ListItem button key={index} id={"history-" + index} className="item" onClick={() => this.insertHistory(slide, index, "insert", "outgoing")}>
											<ListItemIcon>
												<LibraryBooksIcon className="slides-list-icon" />
											</ListItemIcon>
											<ListItemText
												primary={slide.content.sub}
												secondary={
													<React.Fragment>
														<Typography className="verse" dangerouslySetInnerHTML={{ __html: slide.content.main }}></Typography>
													</React.Fragment>
												}
											/>
										</ListItem>
									}

									if (slide.type === "custom") {
										return <ListItem button key={index} id={"history-" + index} className="item" onClick={() => this.insertHistory(slide, index, "insert", "outgoing")}>
											<ListItemIcon>
												<CodeIcon className="slides-list-icon" />
											</ListItemIcon>
											<ListItemText
												primary={
													<React.Fragment>
														<Typography className="verse" dangerouslySetInnerHTML={{ __html: slide.content.main }}></Typography>
													</React.Fragment>
												}
											/>
										</ListItem>
									}

									if (slide.type === "image") {
										return <ListItem button key={index} id={"history-" + index} className="item" onClick={() => this.insertHistory(slide, index, "insert", "outgoing")}>
											<ListItemIcon>
												<CameraAltIcon className="slides-list-icon" />
											</ListItemIcon>
											<ListItemText
												primary={
													<React.Fragment>
														<Typography className="verse" dangerouslySetInnerHTML={{ __html: slide.content.main }}></Typography>
													</React.Fragment>
												}
											/>
										</ListItem>
									}

									if (slide.type === "video") {
										return <ListItem button key={index} id={"history-" + index} className="item" onClick={() => this.insertHistory(slide, index, "insert", "outgoing")}>
											<ListItemIcon>
												<VideoCamIcon className="slides-list-icon" />
											</ListItemIcon>
											<ListItemText
												primary={
													<React.Fragment>
														<Typography className="verse" dangerouslySetInnerHTML={{ __html: slide.content.main }}></Typography>
													</React.Fragment>
												}
											/>
										</ListItem>
									}

								})}
							</List>

						</Grid>
					)
					}
				</Grid>
			</div>
		);
	}
}

export default RemotePage;