import { combineReducers } from "redux";
import { withReduxStateSync } from 'redux-state-sync'
import hofcc from "./hofcc";

const rootReducer = combineReducers({ 
    // activeMenu, 
    hofcc,
    // presentations 
});

export default withReduxStateSync(rootReducer);