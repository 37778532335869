import React from 'react';
import { Route } from 'react-router-dom'


import HomePage from './pages/HomePage/HomePage';
import AssetsPage from './pages/AssetsPage/AssetsPage';
import PresentationsPage from './pages/PresentationsPage/PresentationsPage';
import PresentationPage from './pages/PresentationPage/PresentationPage';
import ControllerPage from './pages/PresentationPage/ControllerPage/ControllerPage';
import RemotePage from './pages/PresentationPage/RemotePage/RemotePage';
import LivePage from './pages/PresentationPage/LivePage/LivePage';

const Routes = () => (
    <div>
        {/* <header>
            <Link to="/">Home</Link>
            <Link to="/foo">Foo</Link>
        </header>
        <main>
            <Route exact path="/" component={HomePage}/>
            <Route exact path="/foo" component={FooPage}/>
        </main> */} 

        <Route exact path="/" component={PresentationsPage} />
        <Route exact path="/assets" component={AssetsPage} />     
        <Route exact path="/presentations" component={PresentationsPage} />     
        <Route exact path="/presentation/:presentationid" component={PresentationPage} />     
        <Route exact path="/presentation/c/:presentationid" component={ControllerPage} />  
        <Route exact path="/remote/:presentationid" component={RemotePage} />             
        <Route exact path="/live/:presentationid/:delay?" component={LivePage} /> 
    </div>
)

export default Routes
