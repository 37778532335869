import React, { Component } from 'react';
import { connect } from "react-redux";
import { setActiveMenuTab, initializeData } from '../../redux/actions';
import './HomePage.css';

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
      }

    componentDidMount() {
        // this.props.initializeData();
        this.props.setActiveMenuTab(this.props.location.pathname);
    }
    
    render() {
        return (
            <div className="todo-app">
                <h1>HOFCC Service</h1>
            </div>
        );
    }
}

// export default HomePage;

const mapStateToProps = state => ({
    activeMenu: state.activeMenu
})
  
const mapDispatchToProps = dispatch => ({
    initializeData: () => dispatch(initializeData()),
    setActiveMenuTab: route => dispatch(setActiveMenuTab(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);