import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import BroadcastChannel from 'broadcast-channel';
import './PresentationPage.css';

const options = {
	type: 'localstorage', // (optional) enforce a type, oneOf['native', 'idb', 'localstorage', 'node']
};

const change_theme = new BroadcastChannel('change-theme', options);
const change_text_size = new BroadcastChannel('change-text-size', options);
const change_text_color = new BroadcastChannel('change-text-color', options);
const display_slides = new BroadcastChannel('display-slides', options);
const channel_slide_change = new BroadcastChannel('slide-change', options);
const insert_content = new BroadcastChannel('insert-content', options);
const change_animation = new BroadcastChannel('change-animation', options);
const change_background_image = new BroadcastChannel('change-background-image', options);
const change_text_alignment = new BroadcastChannel('change-text-alignment', options);

class PresentationPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			songs: this.props.songs,
			presentationId: this.props.match.params.presentationid,
			presentations: this.props.presentations,
			currentPresentation: {},
			insertedSongSlides: [],
			currentSlide: 0,
			showSlides: true,
			settings: {
				theme: 'blackout-theme',
				background: 'radial-gradient(#666, #fff)',
				backgroundImage: '',
				textAlign: 'center',
				fontColor: '',
				fontSizes: {
					main: '8rem',
					date: '4rem',
					message: '4rem',
					songTitle: '6.5rem',
					lyrics: '5.6rem',
					verse: '5rem',
					reference: '3.5rem'
				},
				animation: "none"
			},
			currentSlideContent: {
				type: '',
				order: 0,
				details: {
					date: '',
					message: '',
					title: ''
				}
			},
			insertedContent: {
				type: 'custom',
				content: {
					main: '',
					sub: ''
				}
			}
		}
	}

	componentDidMount() {
		change_theme.addEventListener('message', msg => {
			this.changeTheme(msg);
		})
		change_text_size.addEventListener('message', msg => {
			this.changeFontSize(msg);
		});

		change_text_color.addEventListener('message', msg => {
			this.changeFontColor(msg);
		});

		display_slides.addEventListener('message', msg => {
			this.displaySlides(msg);
		});

		channel_slide_change.addEventListener('message', msg => {
			this.changeSlide(msg);
		});

		insert_content.addEventListener('message', msg => {
			this.insertContent(msg);
		});

		change_animation.addEventListener('message', msg => {
			this.changeAnimation(msg);
		});

		change_background_image.addEventListener('message', msg => {
			this.changeBackgroundImage(msg);
		});

		change_text_alignment.addEventListener('message', msg => {
			this.changeTextAlignment(msg);
		});

		document.getElementsByTagName("html")[0].style.fontSize = "15px";
		document.getElementsByTagName("header")[0].style.display = "none";

		localStorage.setItem("songs", JSON.stringify(this.state.songs));
		if (this.state.presentationId) {
			window.open('/presentation/c/' + this.state.presentationId, 'newwindow', 'height=670,width=1400,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no');
		}
	}

	handleBroadcast = () => {

	}

	////////////////////////////////////////////////////////////////////////////////////////////////////
	changeTheme = (theme) => {
		this.setState(prevState => ({
			settings: {
				...prevState.settings,
				theme: theme.value,
				fontColor: ''
			}
		}))
	}

	changeFontSize = (size) => {
		document.getElementsByTagName("html")[0].style.fontSize = size + "px";
	}

	changeFontColor = (value) => {
		this.setState(prevState => ({
			settings: {
				...prevState.settings,
				fontColor: value,
			}
		}))
	}

	displaySlides = (value) => {
		this.setState({ showSlides: value });
	}

	changeSlide = (slide) => {
		this.setState({ currentSlideContent: slide });
		this.animateCSS('.slides-lyrics', this.state.settings.animation);
	}

	insertContent = (content) => {
		this.setState(prevState => ({
			insertedContent: {
				...prevState.insertedContent,
				type: content.type,
				content: content.content
			}
		}))

		this.animateCSS('.slides-custom', this.state.settings.animation);

	}

	changeAnimation = (animation) => {
		this.setState(prevState => ({
			settings: {
				...prevState.settings,
				animation: animation.value,
			}
		}))
	}

	changeBackgroundImage = (pImage) => {
		this.setState(prevState => ({
			settings: {
				...prevState.settings,
				backgroundImage: pImage,
			}
		}))
	}

	changeTextAlignment = (alignment) => {
		this.setState(prevState => ({
			settings: {
				...prevState.settings,
				textAlign: alignment.value,
			}
		}))
	}

	//this.animateCSS('', '')
	animateCSS = (element, animationName, callback) => {
		const node = document.querySelector(element)
		if (node) {
			node.classList.add('animated', animationName)

			function handleAnimationEnd() {
				node.classList.remove('animated', animationName)
				node.removeEventListener('animationend', handleAnimationEnd)

				if (typeof callback === 'function') callback()
			}

			node.addEventListener('animationend', handleAnimationEnd)
		}
	}
	////////////////////////////////////////////////////////////////////////////////////////////////////

	render() {
		const { currentSlideContent, showSlides, settings, insertedContent } = this.state;

		return (
			<div id="presentation-page" className={settings.theme} style={{ backgroundImage: settings.theme !== "whiteout-theme" && settings.theme !== "blackout-theme" && settings.backgroundImage !== "" ? "url(" + settings.backgroundImage + ")" : "" }}>
				{/* <video autoPlay muted loop id="myVideo">
					<source src="/assets/videos/ocean.mp4" type="video/mp4"/>
				</video> */}

				{settings.theme !== "whiteout-theme" && settings.theme !== "blackout-theme" &&
					<img class="logo" alt="logo" src="/assets/images/Logo-White.svg" />
				}

				{settings.theme !== "whiteout-theme" && settings.theme !== "blackout-theme" &&
					<div class="overlay"></div>
				}

				<div className={showSlides ? 'show slides-lyrics' : 'hide'}>
					{currentSlideContent.type === "intro" &&
						<div>
							<Typography className="title" style={{ fontSize: settings.fontSizes.main, color: settings.fontColor, textAlign: settings.textAlign === "" ? "left" : settings.textAlign }} align="center">{currentSlideContent.details.title}</Typography>
							<Typography className="date" style={{ fontSize: settings.fontSizes.date, color: settings.fontColor, textAlign: settings.textAlign === "" ? "left" : settings.textAlign }} align="center">{currentSlideContent.details.date}</Typography>
							<Typography className="message" style={{ fontSize: settings.fontSizes.message, color: settings.fontColor, textAlign: settings.textAlign === "" ? "left" : settings.textAlign }} align="center">{currentSlideContent.details.message}</Typography>
						</div>
					}

					{currentSlideContent.type === "title" &&
						<div>
							<Typography className="songTitle" style={{ fontSize: settings.fontSizes.songTitle, color: settings.fontColor, textAlign: settings.textAlign === "" ? "left" : settings.textAlign }} align="center" dangerouslySetInnerHTML={{ __html: currentSlideContent.details }}></Typography>
						</div>
					}

					{currentSlideContent.type === "lyrics" &&
						<div>
							<Typography className="lyrics" style={{ fontSize: settings.fontSizes.lyrics, color: settings.fontColor, textAlign: settings.textAlign === "" ? "left" : settings.textAlign }} align="left" dangerouslySetInnerHTML={{ __html: currentSlideContent.details }}></Typography>
						</div>
					}
				</div>

				<div className={!showSlides ? 'show slides-custom' : 'hide'}>
					{insertedContent.type === "verse" &&
						<div>
							<Typography className="reference" style={{ fontSize: settings.fontSizes.reference, color: settings.fontColor }} align="center" dangerouslySetInnerHTML={{ __html: insertedContent.content.sub }}></Typography>
							<Typography className="verse" style={{ fontSize: settings.fontSizes.verse, color: settings.fontColor }} align="center" dangerouslySetInnerHTML={{ __html: insertedContent.content.main }}></Typography>
						</div>
					}

					{insertedContent.type === "image" &&
						<div>
							<img className="image" alt="slide note" src={insertedContent.content.main}></img>
						</div>
					}


					{insertedContent.type === "video" &&
						<div className="video">
							<iframe title="noteFrame" src={insertedContent.content.main} frameBorder='0' allowfullscreen="allowfullscreen"></iframe>
						</div>
					}

					{insertedContent.type === "custom" &&
						<div className="custom" dangerouslySetInnerHTML={{ __html: (insertedContent.content.main + ("<span>" + insertedContent.content.main.replace(/<[^>]+>/g, '') + "</span>") ) }} style={{ fontSize: settings.fontSizes.verse, color: settings.fontColor }}></div>
					}
				</div>
			</div>
		);
	}
}

PresentationPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default PresentationPage;